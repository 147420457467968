/* eslint-disable no-mixed-spaces-and-tabs */
<template>
  <div class="chatall" :style="{ height: sheight + 'px' }">
    <div class="chat-left">
      <div class="search-box">
        <el-input
          :clearable="true"
          placeholder="搜索"
          prefix-icon="el-icon-search"
          v-model="searchinput"
        >
        </el-input>
      </div>
      <div class="msg-list">
        <div class="ul-box" slot="reference" :style="{ height: sheight - 50 + 'px' }" v-if="active != 5">
          <div class="user-list"
               v-for="(item, index) in searchUserlist || userInfoList"  :key="index"
                :class="{'m-active':liststuts == item.userId,}"
               @contextmenu.prevent="onContextmenu($event, item, 0)"
               @click="personSelector(item, 1)"
          >
            <div class="ul-pic">
              <img v-if="item.avatar && item.avatar !== null" :src="loadUserBaseUrl + item.avatar"/>
              <img v-else src="https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png"/>
            </div>
            <div class="msg-info">
              <div class="mi-one">
                <div class="name font16">

                  {{ item.displayName }}
                  <span v-if="item.msgInfo && item.msgInfo.unread_num > 0">{{ item.msgInfo.unread_num }}</span>
                </div>
                <div class="time font14" v-if="active===1 || active===2">
                  {{ getTime(item.msgInfo.timestamp) }}
                </div>
              </div>
              <div class="mi-two font14" v-if="item.msgInfo.msg_bodies.length > 0
              && (item.msgInfo.msg_bodies[0].type === 'txt' || item.msgInfo.msg_bodies[0].type === 'TEXT')">
                {{
                  item.msgInfo.msg_bodies[0].msg === '###freeman-message-recall-content###'
                      ? "有消息被撤回"
                      : item.msgInfo.msg_bodies[0].msg
                }}
              </div>
              <div class="mi-two font14" v-else-if="item.msgInfo.msg_bodies.length > 0
              && (item.msgInfo.msg_bodies[0].type === 'img' || item.msgInfo.msg_bodies[0].type === 'IMAGE')">
                {{ "[ 图片 ]" }}
              </div>
              <div class="mi-two font14" v-else-if="item.msgInfo.msg_bodies.length > 0
              && (item.msgInfo.msg_bodies[0].type === 'custom' || item.msgInfo.msg_bodies[0].type === 'CUSTOM')">
                {{ "       " }}
              </div>
              <div class="mi-two font14" v-else-if="item.msgInfo.msg_bodies.length > 0
              && (item.msgInfo.msg_bodies[0].type === 'loc' || item.msgInfo.msg_bodies[0].type === 'LOCATION')">
                {{ "[ 地址 ]" }}
              </div>
              <div class="mi-two font14" v-else>
                {{      }}
              </div>
            </div>
            <!--
            <div class="nodu" v-if="dustuts" @click="dustuts = false">
              标记未读
            </div>  -->
          </div>
        </div>
        <div class="ul-box" :style="{ height: sheight - 50 + 'px' }" v-else>  <!-- active = 5 -->
          <div
              class="user-list"
              v-for="(item, index) in searchUserlist || userInfoList" :key="index"
              :class="{'m-active':liststuts == item.userId,}"
              @contextmenu.prevent="onContextmenu($event, item, 0)"
              @click="personSelector(item, 1005)"
          >
                <div class="ul-pic">
                  <img v-if="item.avatar && item.avatar !== null" :src="loadUserBaseUrl + item.avatar"/>
                  <img v-else src="https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png"/>
                </div>
                <div class="msg-info">
                  <div class="mi-one">
                    <div class="name font16">
                      {{ item.displayName }}
                    </div>
                    <div class="time font14">
                      {{        }}
                    </div>
                  </div>
                  <div class="mi-two font14">
                    {{      }}
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-right">
      <!-- 右侧查看所有沟通过的牛人消息哦 -->
      <div class="youce-look" :style="{ height: sheight + 'px' }" v-if="false">
        <img src="../../assets/images/common/position-nodata.png" />
        <div class="">右侧查看所有沟通过的牛人消息哦</div>
      </div>
      <!-- 聊天和企业 -->
      <div class="chat-main" v-if="userInfoList.length > 0">
        <div class="tab-box">
          <div
            class="tb-main"
            :class="activenum == 1 ? 'tb-active' : ''"
            @click="changenum(1)"
          >
            聊天
          </div>
          <div
            class="tb-main"
            :class="activenum == 2 ? 'tb-active' : ''"
            @click="changenum(2)"
          >
            在线简历
          </div>
        </div>
        <!-- 聊天界面 -->
        <div class="" v-if="activenum == 1 && userInfoList.length">
          <div class="chat-header font16">
<!--            <div class="c-name">{{ personConfig.displayName }}</div>-->
            <div class="tt-tishi">
              <div class="text">正在沟通岗位：{{ personConfig.companyJobName }}</div>
              <div class="span"
                   v-if="jobOption.selfJobList && jobOption.selfJobList.length > 0 && jobOption.flag"
                   @click="jobListVisible = !jobListVisible">切换岗位</div>
            </div>
            <div class="job-select font16" v-if="jobListVisible">
              <div v-for="(job, index) in jobOption.selfJobList" :key="index" style="display: flex; align-items: center; text-align: center;">
                <p v-if="job.related && personConfig.companyJobName === job.label" style="color: green;" @click="changeSelfJob(job)">{{ job.label }}</p>
                <p v-if="job.related && personConfig.companyJobName !== job.label" style="color: white;" @click="changeSelfJob(job)">{{ job.label }}</p>
<!--                <p v-else style="color: #969896;">{{ job.label }}</p>-->
                <el-divider v-if="job.related && index<jobOption.selfJobList.length-1" direction="vertical"></el-divider>
              </div>

            </div>
            <!-- <div class="gs-name">{{personConfig.companyJobName}}</div> -->
          </div>

          <div
            class="chat-content"
            ref="chatbox"
            :style="{ height: sheight - 380 + 'px' }"
          >
            <div class="list-content" v-if="personConfig">
              <div class="person-info">
                <div class="p-picurl">
                  <img
                      :src="personConfig.avatar !== '' && personConfig.avatar !== null
                      ? loadUserBaseUrl + personConfig.avatar
                      : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'
                    "
                  />
                </div>
                <div class="p-news">
                  <div class="pn-name">
                    <div class="namemain">
                      <span class="font22">{{ personConfig.displayName }}</span>
                      <img
                        src="../../assets/images/person/boy.png"
                        v-if="personConfig.sex == 1"
                      />
                      <img src="../../assets/images/person/girl.png" v-else />
                      <span class="font14">Free</span>
                    </div>
                    <div class="score font14">
                      <span>{{ personConfig.score }}</span>
                    </div>
                  </div>
                  <div class="beizhu font14">
                    <div class="" style="margin-right: 15px">
                      {{ personConfig.expYear }}年 | {{
                        personConfig.educationList &&
                        personConfig.educationList.length > 0
                          ? personConfig.educationList[0].educationType ? personConfig.educationList[personConfig.educationList.length - 1].educationType.itemText : " - "
                          : " - "
                      }} | {{
                        new Date().getYear() -
                        new Date(personConfig.birthday).getYear()
                      }}岁 | {{ personConfig.jobLevelNames }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="transitionbox">
                <div class="t-one font14">
                  <img src="../../assets/images/person/gangwei.png" />
                  <span
                    @click="getMoreWork(personConfig)"
                    v-if="personConfig.userWorkExpList && personConfig.userWorkExpList.length > 0"
                  >
                    {{ personConfig.userWorkExpList[0].companyName }} ·
                    {{ personConfig.userWorkExpList[0].jobName }}
                  </span>
                  <i
                    v-if="personConfig.userWorkExpList && personConfig.userWorkExpList.length > 1"
                    :class="
                      personConfig.moreShow
                        ? 'el-icon-arrow-up'
                        : 'el-icon-arrow-down'
                    "
                    @click="getMoreWork(personConfig)"
                  ></i>
                </div>
                <el-collapse-transition>
                  <div v-show="personConfig.moreShow">
                    <div
                      class="t-one font14"
                      v-show="index > 0"
                      v-for="(item, index) in personConfig.userWorkExpList"
                      :key="index"
                    >
                      <img src="../../assets/images/person/gangwei.png" />
                      <span>{{ item.companyName?item.companyName:"" }} · {{ item.jobName?item.jobName:"" }}</span>
                    </div>
                  </div>
                </el-collapse-transition>
              </div>
              <div class="label-info">
                <div class="li-left font14">
                  <span
                    v-show="index < 5"
                    v-for="(item, index) in personConfig.skillIds"
                    :key="index"
                    >{{ item.itemText }}</span>
                </div>
              </div>
            </div>

            <!-- recordContent 聊天记录数组-->
            <div v-for="(itemc, indexc) in recordContent" :key="indexc">
              <!-- 消息体 -->
              <div class="word">
                <template v-if="messageSort(itemc) > 10">
                  <div class="c-time">
                    {{ getTime(itemc.time) }}
                  </div>
                  <!-- 求职者（对方）聊天面板-->
                  <div class="infoboxes" v-if="itemc.from == 'free_user_' + personConfig.id">
                    <div style="display: flex">
                      <img :src="itemc.ext.chat_attribute_avatar_from !== '' && itemc.ext.chat_attribute_avatar_from !== null
                          ? loadUserBaseUrl + itemc.ext.chat_attribute_avatar_from
                          : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'
                      " />
                      <div class="info">
                        <!-- <p class="time">无名氏 2021.12.10</p> -->
                        <!-- 聊天内容-->
                        <img style="width: auto; max-width: 200px; height: auto; padding: 6px 0 0 14px; border-radius: 0;"
                            class="pic_content" v-if="messageSort(itemc) === 12"
                            :src="itemc.url"/>
                        <div v-else class="info-content">{{ itemc.data }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- 企业端（己方）-->
                  <div class="word-my" v-else>
                    <div class="info">
                      <div v-show="Date.now() - itemc.time >= 2 * 60 * 1000">
                        <img
                            style="width: auto; max-width: 200px; height: auto; padding: 6px 14px 0 0; border-radius: 0"
                            class="pic_content" v-if="messageSort(itemc) === 12"
                            :src="itemc.url"/>
                        <div class="amap-wrapper loc_content" style="float: right; margin-right: 10px"
                             v-else-if="messageSort(itemc) === 14">
                          <el-amap
                              class="amap-box"
                              :zoom="itemc.data.zoom"
                              :center="itemc.data.center"
                              :mapStyle="mapStyle"
                          >
                            <el-amap-marker
                                v-for="(marker, index) in itemc.data.markers"
                                :position="marker.position"
                                :events="marker.events"
                                :vid="index"
                                :icon="marker.icon"
                                v-bind:key="index"
                            >
                            </el-amap-marker>
                            <el-amap-info-window
                                v-if="itemc.data.window"
                                :position="itemc.data.window.position"
                                :visible="itemc.data.window.visible"
                                :content="itemc.data.window.content"
                                :offset="itemc.data.window.offset"
                                :is-custom="true"
                            >
                              <div id="msg-from-window">
                                <p>{{ itemc.data.markers[0].text }}</p>
                              </div>
                            </el-amap-info-window>
                          </el-amap>
                        </div>
                        <div v-else class="info-content">{{ itemc.data }}</div>
                      </div>
                      <div v-show="Date.now() - itemc.time < 2 * 60 * 1000" slot="reference">
                          <div @contextmenu.prevent="contextMenuOption($event, itemc)">
                            <img
                                style="width: auto; max-width: 200px; height: auto; padding: 6px 14px 0 0; border-radius: 0"
                                class="pic_content" v-if="messageSort(itemc) === 12"
                                :src="itemc.url"/>
                            <div class="amap-wrapper loc_content" style="float: right; margin-right: 10px"
                                 v-else-if="messageSort(itemc) === 14">
                              <el-amap
                                  class="amap-box"
                                  :zoom="itemc.data.zoom"
                                  :center="itemc.data.center"
                                  :mapStyle="mapStyle"
                              >
                                <el-amap-marker
                                    v-for="(marker, index) in itemc.data.markers"
                                    :position="marker.position"
                                    :events="marker.events"
                                    :vid="index"
                                    :icon="marker.icon"
                                    v-bind:key="index"
                                >
                                </el-amap-marker>
                                <el-amap-info-window
                                    v-if="itemc.data.window"
                                    :position="itemc.data.window.position"
                                    :visible="itemc.data.window.visible"
                                    :content="itemc.data.window.content"
                                    :offset="itemc.data.window.offset"
                                    :is-custom="true"
                                >
                                  <div id="msg-to-window">
                                    <p>{{ itemc.data.markers[0].text }}</p>
                                  </div>
                                </el-amap-info-window>
                              </el-amap>
                            </div>
                            <div v-else class="info-content">{{ itemc.data }}</div>
                          </div>
                          <ul v-show="visibleShow && itemc.visible" class="info-recall" >
                            <li class="el-dropdown-menu__item" @click="mouseclick(itemc)">
                              <i ></i>撤回
                            </li>
                          </ul>
                        </div>
                    </div>
                    <img
                        v-if="current.avatar"
                        :src="loadBaseUrl + current.avatar"
                    />
                    <img
                        v-else
                        src="https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png"
                    />
                  </div>
                </template>
                <!-- 消息撤回-->
                <div v-if="messageSort(itemc) === 2" class="recall">
                  <div class="recallTips">对方撤回了一条消息</div>
                </div>
                <div v-if="messageSort(itemc) === 1" class="recall">
                  <div class="recallTips">你撤回了一条消息</div>
                </div>
                <div v-if="messageSort(itemc) === 3" class="c-details">
                  <div class="detailsAgreeTips">微信号申请已发送</div>
                </div>
                <div v-if="messageSort(itemc) === 4" class="c-details">
                  <div class="detailsAgreeTips">手机号申请已发送</div>
                </div>
                <div v-if="messageSort(itemc) === 5" class="c-details">
                  <div class="detailsRejectTips">对方拒绝了微信号申请</div>
                </div>
                <div v-if="messageSort(itemc) === 6" class="c-details">
                  <div class="detailsRejectTips">对方接受了微信号申请</div>
                </div>
                <div v-if="messageSort(itemc) === 7" class="c-details">
                  <div class="detailsRejectTips">对方拒绝了手机号申请</div>
                </div>
                <div v-if="messageSort(itemc) === 8" class="c-details">
                  <div class="detailsRejectTips">对方接受了手机号申请</div>
                </div>
              </div>
            </div>
            <div
              class=""
              style="text-align: center; margin-top: 30px"
              v-if="phoneStatus === 1 && phoneContent !== ''">
              <span
                style="
                  background-color: #fff0e0;
                  color: #ff8400;
                  padding: 10px 20px;
                  font-size: 14px;
                ">
                {{ "已获取对方手机号为 "+phoneContent }}
              </span>
            </div>
            <div
              class=""
              style="text-align: center; margin-top: 30px"
              v-if="wxStatus === 1 && wxContent !== ''">
              <span
                style="
                  background-color: #fff0e0;
                  color: #ff8400;
                  padding: 10px 20px;
                  font-size: 14px;
                ">
                {{ "已获取对方微信号为 "+wxContent }}
              </span>
            </div>
            <div
                class=""
                style="text-align: center; margin-top: 30px"
                v-if="tipStatus">
              <span
                  style="
                  background-color: #fff0e0;
                  color: #ff8400;
                  padding: 10px 20px;
                  font-size: 14px;
                ">
                {{ "加载中,稍等" }}
              </span>
            </div>
          </div>
          <div class="chat-input">
            <div class="c-caozuo">
              <el-tooltip
                class="item"
                effect="light"
                content="表情"
                placement="top-start"
              >
                <img
                  src="../../assets/images/common/biaoqing.png"
                  @click="
                    (emoji = !emoji), (langstuts = false), (jlstuts = false)
                  "
                />
              </el-tooltip>
              <el-tooltip
                class="item"
                :disabled="langstuts"
                effect="light"
                content="常用语"
                placement="top-start"
              >
                <img
                  src="../../assets/images/common/changyongyu.png"
                  @click="
                    (langstuts = !langstuts), (emoji = false), (jlstuts = false)
                  "
                />
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="light"
                content="位置"
                placement="top-start"
              >
                <img
                  src="../../assets/images/common/dizhi.png"
                  @click="getAdress"
                />
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="light"
                content="手机号"
                placement="top-start"
              >
                <img
                  src="../../assets/images/common/shoujihao.png"
                  @click="getPhone"
                />
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="light"
                content="微信号"
                placement="top-start"
              >
                <img
                  src="../../assets/images/common/weixin.png"
                  @click="getWx"
                />
              </el-tooltip>
              <el-tooltip
                class="item"
                :disabled="jlstuts"
                effect="light"
                content="简历"
                placement="top-start"
              >
                <img
                  src="../../assets/images/common/ziliao.png"
                  @click="
                    (jlstuts = !jlstuts), (emoji = false), (langstuts = false)
                  "
                />
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="light"
                content="邀请面试"
                placement="top-start"
              >
                <img
                  src="../../assets/images/common/renwuzx.png"
                  @click="toInterview"
                />
              </el-tooltip>
              <el-tooltip
                  class="item"
                  effect="light"
                  content="发送图片"
                  placement="top-start"
              >
                <el-upload style="padding-top: 5px"
                           action="https://freemen.work/user/file/upload/"
                           ref="upload"
                           multiple
                           :on-success="handleSuccess">
                  <img
                      src="../../assets/images/common/pic.png"
                  />
                </el-upload>
              </el-tooltip>


              <!-- 表情弹窗 -->
              <div class="emoji" v-if="emoji">
                <span @click="emojiClick('😀')">😀</span>
                <span @click="emojiClick('😁')">😁</span>
                <span @click="emojiClick('😂')">😂</span>
                <span @click="emojiClick('😃')">😃</span>
                <span @click="emojiClick('😄')">😄</span>
                <span @click="emojiClick('😅')">😅</span>
                <span @click="emojiClick('😆')">😆</span>
                <span @click="emojiClick('😉')">😉</span>
                <span @click="emojiClick('😊')">😊</span>
                <span @click="emojiClick('😋')">😋</span>
                <span @click="emojiClick('😎')">😎</span>
                <span @click="emojiClick('😍')">😍</span>
                <span @click="emojiClick('😘')">😘</span>
                <span @click="emojiClick('😗')">😗</span>
                <span @click="emojiClick('😇')">😇</span>
                <span @click="emojiClick('😑')">😑</span>
                <span @click="emojiClick('😶')">😶</span>
                <span @click="emojiClick('😏')">😏</span>
                <span @click="emojiClick('😣')">😣</span>
                <span @click="emojiClick('😥')">😥</span>
                <span @click="emojiClick('😮')">😮</span>
                <span @click="emojiClick('😯')">😯</span>
                <span @click="emojiClick('😪')">😪</span>
                <span @click="emojiClick('😫')">😫</span>
                <span @click="emojiClick('😴')">😴</span>
                <span @click="emojiClick('😌')">😌</span>
                <span @click="emojiClick('😛')">😛</span>
                <span @click="emojiClick('😜')">😜</span>
                <span @click="emojiClick('😝')">😝</span>
                <span @click="emojiClick('😒')">😒</span>
                <span @click="emojiClick('😓')">😓</span>
                <span @click="emojiClick('😔')">😔</span>
                <span @click="emojiClick('😕')">😕</span>
                <span @click="emojiClick('😲')">😲</span>
                <span @click="emojiClick('😷')">😷</span>
                <span @click="emojiClick('😖')">😖</span>
                <span @click="emojiClick('😞')">😞</span>
                <span @click="emojiClick('😟')">😟</span>
                <span @click="emojiClick('😤')">😤</span>
                <span @click="emojiClick('😢')">😢</span>
                <span @click="emojiClick('😭')">😭</span>
                <span @click="emojiClick('😦')">😦</span>
                <span @click="emojiClick('😧')">😧</span>
                <span @click="emojiClick('😨')">😨</span>
                <span @click="emojiClick('😬')">😬</span>
                <span @click="emojiClick('😰')">😰</span>
                <span @click="emojiClick('😱')">😱</span>
                <span @click="emojiClick('😳')">😳</span>
                <span @click="emojiClick('😵')">😵</span>
                <span @click="emojiClick('😡')">😡</span>
                <span @click="emojiClick('😠')">😠</span>
              </div>
              <!-- 常用语弹窗 -->
              <div class="langage" v-if="langstuts">
                <img class="xiala" src="../../assets/images/common/xaila.png" />
                <div class="lang-main font14">
                  <div class="lm-text">常用语</div>
                  <div class="set" @click="lanSet">设置</div>
                </div>
                <div
                  class="lang-main font14"
                  v-for="(item, index) in phraseList"
                  :key="index"
                  @click="lanClick(item)"
                >
                  <div class="lm-text">
                    {{ item.content }}
                  </div>
                </div>
              </div>
              <!-- 下载简历 -->
              <div class="jianli" v-if="jlstuts">
                <img
                  class="xiala1"
                  src="../../assets/images/common/xaila.png"
                />
                <div class="lang-main font14">
                  <div class="lm-text">简历</div>
                  <div class="set" @click="download">下载</div>
                </div>
                <div class="jlbox" @click="download">
                  <img src="../../assets/images/common/biaodan.png" />
                </div>
              </div>
            </div>
            <!-- :autosize="{ minRows: 4, maxRows: 4}" -->
            <div class="chat-input-box">
              <el-button class="send_msg" @click="sendMes">发送</el-button>
              <el-input
                id="input"
                class="input-textarea"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 5 }"
                placeholder=""
                v-model="inputinfo"
                @keyup.enter.native="sendMes"
              >
              </el-input>
            </div>
          </div>
        </div>
        <!-- 在线简历界面 -->
        <div
          class="qy-info"
          :style="{ height: sheight - 140 + 'px' }"
          v-if="activenum == 2 && userInfoList.length"
        >
          <div class="details">
            <div class="infobox">
              <div class="person-info">
                <div class="p-picurl">
                  <img
                      :src="personConfig.avatar !== '' && personConfig.avatar !== null
                      ? loadUserBaseUrl + personConfig.avatar
                      : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'
                    "
                  />
                  <span
                    class="bofang font20"
                    v-if="personConfig.resUrl"
                    @click="videoshow()"
                    ><i class="el-icon-video-play"></i
                  ></span>
                </div>
                <div class="p-news">
                  <div class="pn-name">
                    <div class="namemain">
                      <span class="font22">{{ personConfig.displayName }}</span>
                      <img
                        src="../../assets/images/person/boy.png"
                        v-if="personConfig.sex == 1"
                      />
                      <img src="../../assets/images/person/girl.png" v-else />
                    </div>
                    <div class="score font14">
                      <span>{{ personConfig.score }}</span>
                    </div>
                  </div>
                  <div class="beizhu font14">
                    {{ personConfig.expYear }}年 | {{
                      personConfig.educationList && personConfig.educationList.length > 0
                        ? personConfig.educationList[0].educationType ? personConfig.educationList[personConfig.educationList.length - 1].educationType.itemText : " - "
                        : " - "
                    }} | {{
                      new Date().getYear() -
                      new Date(personConfig.birthday).getYear()
                    }}岁 | {{ personConfig.jobLevelNames }}
                  </div>
                </div>
              </div>
              <div class="worktime">
                <div class="erji-tit font22">期望工作时间</div>
                <div class="time-main">
                  <img src="../../assets/images/person/timeqi.png" />
                  <div class="">{{ datatime(personConfig.startDate) }}</div>
                  <span>-</span>
                  <img src="../../assets/images/person/timezhi.png" />
                  <div class="">
                    {{
                      datatime(personConfig.endDate)
                        ? datatime(personConfig.endDate)
                        : "/"
                    }}
                  </div>
                </div>
              </div>
              <div class="intention">
                <div class="erji-tit font22">期望职位</div>
                <div class="workinfo">
                  <div class="font18">
                    {{ personConfig.jobName }} {{ personConfig.companyJobName }}
                  </div>
                  <span
                    >{{ personConfig.city }} |
                    {{ personConfig.jobLevelNames }}</span
                  >
                </div>
                <div class="workbiaoqian font14">
                  <span
                    v-for="(item, index) in personConfig.skillIds"
                    :key="index"
                    >{{ item.itemText }}</span
                  >
                </div>
                <div class="workexperience" v-if="personConfig.userWorkExpList && personConfig.userWorkExpList.length>0">
                  <div class="erji-tit font22">工作经历</div>
                  <div
                    class="experiencelist"
                    v-for="(item, index2) in personConfig.userWorkExpList"
                    :key="index2"
                  >
                    <div class="gs-info">
                      <div class="gi-left font18">
                        {{ item.companyName }}
                        <span class="font14">{{ item.jobName }}</span>
                      </div>
                      <div class="gi-right font14">
                        {{ item.startDate }}-{{ item.endDate }}
                      </div>
                    </div>
                    <div class="messageall font16">
                      <div class="">{{ item.jobDescription }}</div>
                    </div>
                  </div>
                </div>
                <div class="workexperience" v-if="personConfig.projectExpList && personConfig.projectExpList.length>0">
                  <div class="erji-tit font22">项目经历</div>
                  <div
                    class="experiencelist"
                    v-for="(item, index2) in personConfig.projectExpList"
                    :key="index2"
                  >
                    <div class="gs-info">
                      <div class="gi-left font18">
                        {{ item.projectName }}
                      </div>
                      <div class="gi-right font14">
                        {{ item.projectStartDate }}-{{ item.projectEndDate }}
                      </div>
                    </div>
                    <div class="workbiaoqian font14">
                      <span
                        v-for="(sub, i) in item.projectTechnology"
                        :key="i"
                        >{{ sub.itemText }}</span
                      >
                    </div>
                    <div class="messageall font16">
                      <div class="">{{ item.projectDescription }}</div>
                    </div>
                  </div>
                </div>
                <div class="education" v-if="personConfig.educationList && personConfig.educationList.length>0">
                  <div class="erji-tit font22">教育经历</div>
                  <div
                    class="education-main"
                    v-for="(item, index) in personConfig.educationList"
                    :key="index"
                  >
                    <div class="em-left font18">
                      {{ item.schoolName }} | {{ item.major }} |
                      {{ item.educationType ? item.educationType.itemText : ""}}
                    </div>
                    <div class="em-right font14">
                      {{ item.startDate }}-{{ item.endDate }}
                    </div>
                  </div>
                </div>
                <div class="education">
                  <div class="erji-tit font22">资格证书</div>
                  <div
                    class="education-main"
                    v-for="(item, index) in personConfig.honorCertList"
                    :key="index"
                  >
                    <div class="em-left font18">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
                <div class="education">
                  <div class="erji-tit font22">外语能力</div>
                  <div
                    class="education-main"
                    v-for="(item, index) in personConfig.languageList"
                    :key="index"
                  >
                    <div class="em-left font18">
                      {{ item.languageType.itemText }} |
                      {{ item.languageLevel.itemText }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 工作地点弹窗 -->
      <!-- :close-on-click-modal="false" -->
      <el-dialog
        :visible.sync="workDialogVisible"
        width="600px"
        center
        :show-close="false"
        :modal-append-to-body="false"
        class="work-box-out"
      >
        <div class="work-box">
          <span class="closeicon" @click="closeworkDialogbox()">
            <i class="el-icon-error"></i>
          </span>
          <div class="wb-tit font20">工作地点</div>
          <div class="frombox">
            <el-form
              :model="workForm"
              ref="workForm"
              label-width="150px"
              class="demo-ruleForm"
            >
              <el-form-item
                label="街道/写字楼："
                prop="street"
                :rules="[{ required: true, message: '请填写真实地址' }]"
              >
                <el-select
                  v-model="tipInput"
                  class="tipInput"
                  filterable
                  remote
                  reserve-keyword
                  clearable
                  placeholder="请输入行政区、街道、写字楼"
                  :remote-method="remoteMethod"
                  :loading="loading"
                  @change="tipChange"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in tips"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ item.district }}</span
                    >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="门牌号："
                prop="housenumber"
                :rules="[{ required: true, message: '请填写真实地址' }]"
              >
                <el-input
                  type="text"
                  style="width: 100%"
                  v-model="workForm.housenumber"
                  placeholder="请输入行政区、街道、写字楼"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <img
                v-if="!markers.length"
                class="amap-wrapper"
                style="width: 100%"
                src="../../assets/images/common/map.png"
              />
              <div class="amap-wrapper" v-else style="width: 100%">
                <el-amap
                  class="amap-box"
                  :zoom="zoom"
                  :center="center"
                  :mapStyle="mapStyle"
                >
                  <el-amap-marker
                    v-for="(marker, index) in markers"
                    :position="marker.position"
                    :events="marker.events"
                    :vid="index"
                    :icon="marker.icon"
                    v-bind:key="index"
                  >
                  </el-amap-marker>
                  <el-amap-info-window
                    v-if="window"
                    :position="window.position"
                    :visible="window.visible"
                    :content="window.content"
                    :offset="window.offset"
                    :is-custom="true"
                  >
                    <div id="info-window">
                      <p>{{ window.address }}</p>
                    </div>
                  </el-amap-info-window>
                </el-amap>
              </div>
              <el-form-item>
                <el-button class="qux" @click="workDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitForm('workForm')">确认</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>

      <!-- 无记录 -->
      <div class="nodata" v-if="!userInfoList.length">
        <img src="../../assets/images/person/p-nodata.png" />
        <div class="font18">暂无消息</div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "../../assets/images/size.png";
import Vue from 'vue'
import Contextmenu from "vue-contextmenujs"
import axios from "axios";
Vue.use(Contextmenu);
let serchTimer = null;
let tempTimer = null;
export default {
  props: ["navActive"],
  inject:['reload'],
  data() {
    return {
      active: 1,
      sheight: document.documentElement.scrollHeight - 160,
      searchinput: "",
      dustuts: false,
      liststuts: 0,
      userList: [],
      activenum: 1,
      recordContent: [
        // mineMsg,headUrl,nickName,timestamp,contactText
        // {id:1,mineMsg}
      ],
      allRecoed: {},
      inputinfo: "",
      workDialogVisible: false, //工作地点弹窗
      workForm: {
        street: "",
        housenumber: "",
      },
      zoom: 14,
      center: [],
      mapStyle: "amap://styles/8b6be8ec497009e17a708205348b899a", //设置地图样式
      markers: [],
      windows: [],
      tipInput: "",
      loading: false,
      map: undefined,
      tips: [],
      window: "",
      // 操作弹窗
      langstuts: false,
      jlstuts: false,
      // 电话号获取状态 -1未获取，0已申请，1已获取
      phoneStatus: -1,
      phoneContent: "",
      // 微信号获取状态 -1未获取，0已申请，1已获取
      wxStatus: -1,
      wxContent:"",
      tipStatus: false,
      emoji: false,
      mesConfig: {
        pageNo: 1,
        pageSize: 10,
        status: "",
        type: "",
      },
      phraseList: [],
      // 切换 "正在沟通岗位" 选项, selfJobList: 所有岗位; relatedJobList: 关联过(不需要再使用聊天卡的相关)岗位
      jobOption: {
        flag: false,
        selfJobList: [],
        relatedJobList: [],
      },
      jobListVisible: false,
      userDetailInfoListCache: [],  // talkedList 沟通过用户详细信息列表本地缓存
      userInfoList: [],     // 聊天用户列表
      searchUserlist: null, // 搜索用聊天用户列表
      personConfig: {},   // 当前聊天对象
      // 新添加聊天对象
      newAddPersonInfo: {
        isNewAdd: false,
        companyJobId: -1,
        companyJobName: "",
        userJobId: -1,
        userId: -1,
        personData: {},
      },
      // 当前companyUserId对应用户信息
      current: {},
      // 撤回右键菜单显隐flag
      visibleShow: false,
      loadUserBaseUrl:"",
      loadBaseUrl:"",
      // loading 加载flag
      reloading: [],
      // 消息发送状态 flag
      sending: false,
    };
  },
  created() {
    // console.log("created in chat------------window.IMlogin: check login, add listen----");
    // console.log("---- axios.defaults.baseURL ----", axios.defaults.baseURL);
    if(axios.defaults.baseURL){
      if(axios.defaults.baseURL.indexOf('freemen.work')===-1){
        this.loadUserBaseUrl = "http://192.168.10.174:8200/" + "user/file/view/";
      } else {
        this.loadUserBaseUrl = axios.defaults.baseURL + "/user/file/view/";
      }
      this.loadBaseUrl = axios.defaults.baseURL + "/company/file/view/";
    } else {
      this.loadBaseUrl = "https://freemen.work/comapny/file/view/"
    }
    window.noIMlogin = true;
    this.routeChecker();
    if (window.IMlogin) {
      // console.log("created in chat------------window.IMlogin: update msg----");
      this.initData();
      this.initListener();
    } else {
      clearTimeout(tempTimer);
      console.log("等待登陆");
      setTimeout(() => {
        if(window.IMlogin){
          this.initData();
          this.initListener();
        }
      }, 500);
    }
  },
  activated() {
    // console.log("activated = " + this.navActive, "------chat chat activated--------")
    // window.noIMlogin = true;
    if(this.active !== this.navActive-0){
      this.active = this.navActive-0;
    }
    window.removeEventListener("click", this.closeMenu); // 关闭撤回弹窗
    this.getPhrase();
    this.$api.joblist("get").then((res) => {
      this.jobOption.selfJobList = [];
      res.data.map((item) => {
        // if (item.status == 1) {
          let obj = {
            label: item.jobName,
            value: item.id,
            related: false,
          };
          this.jobOption.selfJobList.push(obj);
        // }
      });
      // console.log("activated ----- to get Self jobList, res = ", this.jobOption)
    });
  },
  destroyed(){
    this.$IM.removeEventHandlerAll('all');
    window.removeEventListener("click", this.closeMenu); // 关闭撤回弹窗
  },
  watch: {
    searchinput(val) {
      clearTimeout(serchTimer);
      serchTimer = setTimeout(() => {
        if (val.length > 0) {
          this.searchUserlist = this.userInfoList.filter((v) => {
            const name = v.displayName
            // console.log("--------watch-------- search input", name);
            return name.indexOf(val) !== -1;
          });
        } else {
          this.searchUserlist = null;
        }
      }, 100);
    },
    navActive() {
      this.userList = [];
      this.searchUserlist = null;
      this.active = this.navActive - 0;
      this.routeChecker();
      this.getIMList(true);
    },
    visibleShow(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
  },
  methods: {
    // Date  初始化/获取 date
    initListener(){
      this.$IM.removeEventHandlerAll('all');
      this.$IM.onLocationMessage((message) => {
        // console.log("onLocationMessage in chat-------------", message);
        this.onReceiveToUpdateMessage(message);
        // 更新聊天列表显示消息
        setTimeout(() => {
          // this.getIMList(false);
          this.refreshUserList(message, false);
          this.scrollToBottom();
        }, 50);
      });
      this.$IM.onRecallMessage((message) => {
        // console.log("onRecallMessage in chat-------------", message);
        this.onReceiveToDealRecall(message);
        // this.onReceiveToUpdateMessage(message);
        // 更新聊天列表显示消息
        setTimeout(() => {
          this.getIMList(false);
          // this.refreshUserList(message, false);
          this.scrollToBottom();
        }, 50);
      });
      this.$IM.onCustomMessage((message) => {
        // console.log("onCustomMessage in chat-------------", message);
        this.onReceiveToUpdateMessage(message);
        // 更新聊天列表显示消息
        setTimeout(() => {
          // this.getIMList(false);
          this.refreshUserList(message, false);
          this.scrollToBottom();
        }, 50);
      });
      this.$IM.onImageMessage((message) => {
        // console.log("onImageMessage in chat-------------", message);
        this.onReceiveToUpdateMessage(message);
        // 更新聊天列表显示消息
        setTimeout(() => {
          // this.getIMList(false);
          this.refreshUserList(message, false);
          this.scrollToBottom();
        }, 50);
      });
      this.$IM.onTextMessage((message) => {
        // console.log("onTextMessage in chat-------------", message);
        this.onReceiveToUpdateMessage(message);
        // 更新聊天列表显示消息
        setTimeout(() => {
          // this.getIMList(false);
          this.refreshUserList(message, false);
          this.scrollToBottom();
        }, 150);
      });
    },
    initData(){
      this.sheight = document.documentElement.scrollHeight - 160;
      this.current = JSON.parse(sessionStorage.getItem("userInfo"));
      // console.log("Chat page to init data, current userInfo = ", this.current);
      this.$IM.refreshContentCatch();  //  清除游标缓存, 防止检索历史聊天记录时异常
      this.getIMList(true);
      // this.getPhrase();
    },

    // $IM,$api get & send
    getIMList(toSwitchSelect) {
      this.$IM.getList().then((res) => {

        // console.log("getIMList, res = ", res);
        let channelList = [];
        if (res.data.channel_infos.length > 0) {
          channelList = res.data.channel_infos;
        }
        this.getUserList(channelList, toSwitchSelect);
      })
    },
    refreshUserList(message, reOrder){
      // console.log("refreshUserList-----,", message, reOrder);
      let msgId = message.from.split('_')[1]==='user'?message.from.split('_')[2]:message.to.split('_')[2];
      msgId = msgId - 0;
      if(reOrder && this.liststuts !== 0){
        let newList = [];
        this.userInfoList.map((v) =>{
          if(v.userId === msgId){
            // 先初始化， 在赋值
            v.msgInfo = {
              msg_bodies: [{type: '', msg: ''}],
              timestamp: new Date(),
              unread_nu: 0,
            }
            v.msgInfo.msg_bodies[0].type = message.type ? message.type
                : message.ext.chat_attribute_type ? message.ext.chat_attribute_type
                    : message.contentsType;
            v.msgInfo.msg_bodies[0].msg = message.data;
            v.msgInfo.timestamp = message.time;
            // v.msgInfo.unread_num = v.msgInfo.unread_num + 1;
            v.msgInfo.unread_nu = 0;
            newList.unshift(v);
          } else {
            newList.push(v);
          }
        });
        this.userInfoList = newList;
      } else {
        this.userInfoList = this.userInfoList.map((v) =>{
          if(v.userId === msgId){
            v.msgInfo.msg_bodies[0].type = message.contentsType ? message.contentsType : message.ext.chat_attribute_type;
            v.msgInfo.msg_bodies[0].msg = message.data;
            v.msgInfo.timestamp = message.time;
            v.msgInfo.unread_num = v.msgInfo.unread_num + 1;
            return v;
          } else {
            return v;
          }
        });
      }
    },
    routeChecker(){
      if (this.$route.query.active) {
        this.active = this.$route.query.active - 0;
      } else {
        this.active = 1;
      }
      if(this.$route.query.userJobId && this.$route.query.companyJobId){  // 检查是否为新增聊天对象
        // console.log("get data parsed, UserJobId = ", this.$route.query.userJobId, "companyJobId = ", this.$route.query.companyJobId);
        // console.log("local new ADD info = ", this.newAddPersonInfo);
        if(this.newAddPersonInfo.companyJobId !== this.$route.query.companyJobId
            || this.newAddPersonInfo.userJobId !== this.$route.query.companyJobId){
          this.newAddPersonInfo.isNewAdd = true;
          this.newAddPersonInfo.companyJobId = this.$route.query.companyJobId;
          this.newAddPersonInfo.companyJobName = this.$route.query.companyJobName;
          this.newAddPersonInfo.userJobId = this.$route.query.userJobId;
          this.newAddPersonInfo.userId = this.$route.query.userId;
        } else {
          this.newAddPersonInfo.isNewAdd = false;
        }
      }
    },
    checkNewAdd(ids = []){
      // let temp = this.newAddPersonInfo;
      // console.log("checkNewAdd, ids[] = ", ids, "this.new ADD ifo = ", temp);
      if(this.newAddPersonInfo.isNewAdd){
        let flag = false;
        if(ids.length > 0){
          ids.map((v) => {
            if(v.id === this.newAddPersonInfo.userId){
              flag = true;
            }
          });
        }
        if(!flag){
          let config = {
            companyJobId: this.newAddPersonInfo.companyJobId,
            userJobId: this.newAddPersonInfo.userJobId,
          };
          this.$api.getCvdetail("get", config).then((res) => {
            // console.log("update userInfo list with new add, api.getCvdetail.result = ", res);
            if(res.code === 10200){
              let tempFlag = -1; // 判断是否有重复
              let tempUser = res.data;
              if(this.userInfoList.length >0){
                this.userInfoList.map((v, index) => {
                  if(v.userId === tempUser.userId){
                    tempFlag = index;
                  }
                });
              }
              tempUser.msgInfo = {
                id: config.userJobId,
                unread_num: 0,
                timestamp: new Date(),
                msg_bodies: [],
              }

              if(tempFlag === -1){

                this.userInfoList.unshift(tempUser);  // 添加在数组首位
                //
                if (this.newAddPersonInfo.isNewAdd && this.newAddPersonInfo.userId !== -1) {
                  this.liststuts = this.newAddPersonInfo.userId;
                  this.personSelector(this.userInfoList[0], 0);
                }
              } else if(tempFlag >= 0){
                if (this.newAddPersonInfo.companyJobId !== 0 && this.newAddPersonInfo.companyJobName !== ""){
                  tempUser.companyJobId = this.newAddPersonInfo.companyJobId;
                  tempUser.companyJobName = this.newAddPersonInfo.companyJobName;

                  this.userInfoList[tempFlag] = tempUser;  // 更新同名对象

                  this.newAddPersonInfo.isNewAdd = false;
                  this.newAddPersonInfo.companyJobId = 0;
                  this.newAddPersonInfo.companyJobName = "";
                  this.newAddPersonInfo.userJobId = 0;
                }
              }

            } else {
              console.log("未检测到ID为", config.userJobId, "的用户对象----");
            }
            // this.newAddPersonInfo.isNewAdd = false;
            // this.newAddPersonInfo.companyJobId = 0;
            // this.newAddPersonInfo.userJobId = 0;
          });
        } else {
          this.newAddPersonInfo.isNewAdd = false;
          this.newAddPersonInfo.companyJobId = 0;
          this.newAddPersonInfo.companyJobName = "";
          this.newAddPersonInfo.userJobId = 0;
        }
      }
    },
    getPhrase() {         //获取常用语
      this.$api.getPhrase("get").then((res) => {
        this.phraseList = res.data;
      });
    },
    updateToUserInfoList(list, toSwitchSelect) {    // 通过ID查询并存储用户详细信息入表
      // console.log("-----updateToUserInfoList------", toSwitchSelect, ",-- in fuc.list = ", list);

      if (list.length > 0) {
        // console.log("---- check for talked list ### isNewAdd = ", this.newAddPersonInfo.isNewAdd);
        // console.log("---- check for talked list ### userDetailInfoListCache = ", this.userDetailInfoListCache);
        // 第一此获取沟通用户信息列表
        if(this.newAddPersonInfo.isNewAdd || this.userDetailInfoListCache.length === 0){
          this.reloading = this.openLoading();
          this.$api.getTalkList("get").then( (result) => {
            // console.log("####   async updateToUserInfoList --- getTalkList.res = ", result);
            let ids = [];
            list.map((i) => {
              let config = {};
              let tempId = 0;
              if (JSON.parse(i.meta.payload).ext.chat_attribute_userId !== undefined) {
                tempId = JSON.parse(i.meta.payload).ext.chat_attribute_userId;
              } else {
                tempId = JSON.parse(i.meta.payload).from.split("_")[1] === "user"
                    ? JSON.parse(i.meta.payload).from.split("_")[2]
                    : JSON.parse(i.meta.payload).to.split("_")[2];
              }
              config = {
                id: tempId - 0,   // userId
                unread_num: i.unread_num,
                timestamp: i.meta.timestamp,
                msg_bodies: JSON.parse(i.meta.payload).bodies,
              };
              ids.push(config);
              result.data.map((j)=>{
                if(config.id === j.userId){
                  let tempUserInfo = j;
                  tempUserInfo.msgInfo = config;
                  this.userInfoList.push(tempUserInfo);
                }
              });
            });

            this.checkNewAdd(ids);

            // 去重, 本地保存
            const res = new Map();
            this.userDetailInfoListCache = result.data.filter((a) => !res.has(a.userId) && res.set(a.userId, 1));

            if (toSwitchSelect) {
              let tempIndex = 0;
              if (this.liststuts !== 0) {
                this.userInfoList.map((item, index) => {
                  if (this.liststuts === item.userId) {
                    tempIndex = index;
                  }
                });
              }
              if (this.liststuts === this.userInfoList[tempIndex].userId) {
                this.getChatContent(this.liststuts);
              } else {
                this.personSelector(this.userInfoList[tempIndex], 0);
              }
            } else {
              this.getChatContent(this.liststuts);
            }
            this.reloading.close();
          });
        } else {
          let tempTalkedList = this.userDetailInfoListCache;
          this.userInfoList = [];
          list.map((i) => {
            let config = {};
            let tempId = 0;
            if (JSON.parse(i.meta.payload).ext.chat_attribute_userId !== undefined) {
              tempId = JSON.parse(i.meta.payload).ext.chat_attribute_userId;
            } else {
              tempId = JSON.parse(i.meta.payload).from.split("_")[1] === "user"
                  ? JSON.parse(i.meta.payload).from.split("_")[2]
                  : JSON.parse(i.meta.payload).to.split("_")[2];
            }
            config = {
              id: tempId - 0,
              unread_num: i.unread_num,
              timestamp: i.meta.timestamp,
              msg_bodies: JSON.parse(i.meta.payload).bodies,
            };
            tempTalkedList.map((j)=>{
              if(config.id === j.userId){
                let tempUserInfo = j;
                tempUserInfo.msgInfo = config;
                this.userInfoList.push(tempUserInfo);
              }
            });
          });
          // console.log("--------update the userInfoList, current = ", this.userInfoList);
          if (toSwitchSelect) {
            let tempIndex = 0;
            if (this.liststuts !== 0) {
              this.userInfoList.map((item, index) => {
                if (this.liststuts === item.userId) {
                  tempIndex = index;
                }
              });
            }
            if (this.liststuts === this.userInfoList[tempIndex].userId) {
              this.getChatContent(this.liststuts);
            } else {
              this.personSelector(this.userInfoList[tempIndex], 0);
            }
          } else {
            this.getChatContent(this.liststuts);
          }
        }

      } else {
        if(this.active === 1){
          this.checkNewAdd([]);
          setTimeout(() => {
            if(this.userInfoList.length > 0) {
              this.personSelector(this.userInfoList[0], 0);
            }
            this.scrollToBottom();
          }, 100);
          // if(this.userInfoList.length > 0){
          //   this.personSelector(this.userInfoList[0], 0);
          // }
        }
        if(this.active === 3){

          // console.log("---- check for talked list ### isNewAdd = ", this.newAddPersonInfo.isNewAdd);
          // console.log("---- check for talked list ### userDetailInfoListCache = ", this.userDetailInfoListCache);
          if(this.newAddPersonInfo.isNewAdd || this.userDetailInfoListCache.length === 0){
            this.reloading = this.openLoading();
            this.$api.getTalkList("get").then((result) => {
              // console.log("getTalkList for handshake, res = ", result);
              let tempHandshakeList = [];
              result.data.map((k)=>{
                if(k.handStatus === 1){
                  let msgConfig = {
                    id: k.userId,
                    unread_num: 0,
                    timestamp: new Date(),
                    msg_bodies: [],
                  };
                  k.msgInfo = msgConfig;
                  tempHandshakeList.push(k);
                }

              });
              // console.log("getTalkList for handshake，list = ", tempHandshakeList);
              if(tempHandshakeList.length > 0){
                this.userInfoList = tempHandshakeList;
                // console.log("--------update the handshake  userInfoList, current = ", this.userInfoList);
                setTimeout(() => {
                  this.personSelector(this.userInfoList[0], 0);
                  this.scrollToBottom();
                }, 100);
              }
              // 去重, 本地保存
              const res = new Map();
              this.userDetailInfoListCache = result.data.filter((a) => !res.has(a.userId) && res.set(a.userId, 1));

              this.reloading.close();
            });

          } else {

            let tempTalkedList = this.userDetailInfoListCache;
            let tempHandshakeList = [];
            tempTalkedList.map((k)=>{
              if(k.handStatus === 1){
                let msgConfig = {
                  id: k.userId,
                  unread_num: 0,
                  timestamp: new Date(),
                  msg_bodies: [],
                };
                k.msgInfo = msgConfig;
                tempHandshakeList.push(k);
              }
            });
            // console.log("getTalkList for handshake，list = ", tempHandshakeList);
            if(tempHandshakeList.length > 0){
              this.userInfoList = tempHandshakeList;
              // console.log("--------update the handshake  userInfoList, current = ", this.userInfoList);
              setTimeout(() => {
                this.personSelector(this.userInfoList[0], 0);
                this.scrollToBottom();
              }, 100);
            }

          }

        }
      }
    },
    sendMessage(userInfo, content, type){
      if (this.sending) {
        setTimeout(() => {
          this.sending = false;
        }, 1000);
        return;
      }
      this.sending = true;
      // console.log("sendMessage person Config = ", this.personConfig);
      // console.log("sendMessage content =", content, ", type = ",type, ", userInfo = ", userInfo);
      // let msgContent = '';
      // img.res = {
      //   url: "https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png",
      //   width: 1500,
      //   height: 1724
      // }
      // custom.content = cmpy_get_phone || cmpy_get_wechat
      // loc.content = {
      //   addName: this.window.address,
      //   addDetail: this.workForm.street + this.workForm.housenumber,
      //   lng: this.center[0],
      //   lat: this.center[1],
      // }
      this.$IM.send({
        id: this.personConfig.userId,
        content: content,
        type: type,
        chat_attribute_avatar_from: userInfo.avatar,
        chat_attribute_avatar_to: this.personConfig.avatar,
        chat_attribute_company_id: userInfo.companyId,
        chat_attribute_gender_from: userInfo.sex,
        chat_attribute_gender_to: this.personConfig.sex,
        chat_attribute_job_intention_id: this.personConfig.userJobId,
        chat_attribute_nickname_from: userInfo.displayName,
        chat_attribute_nickname_to: this.personConfig.displayName,
        chat_attribute_position_id: this.personConfig.companyJobId,
        chat_attribute_position_name: this.personConfig.companyJobName,
        chat_attribute_handStatus: this.personConfig.handStatus,
        chat_attribute_companyUserId: userInfo.id,
        chat_attribute_userId: this.personConfig.userId,
        success: (id, msgId) => {
          let obj = {
            id: msgId,
            userId: this.personConfig.userId,
            type: type,
            data: content,
            visible: false,
            url: "",
            from: "free_company_" + userInfo.id,
            to: "free_user_" + this.personConfig.userId,
            time: new Date().getTime(),
            ext: {
              chat_attribute_type: type,
              chat_attribute_avatar_from: userInfo.avatar,
              chat_attribute_avatar_to: this.personConfig.avatar,
              chat_attribute_company_id: userInfo.companyId,
              chat_attribute_gender_from: userInfo.sex,
              chat_attribute_gender_to: this.personConfig.sex,
              chat_attribute_job_intention_id: this.personConfig.userJobId,
              chat_attribute_nickname_from: userInfo.displayName,
              chat_attribute_nickname_to: this.personConfig.displayName,
              chat_attribute_position_id: this.personConfig.companyJobId,
              chat_attribute_position_name: this.personConfig.companyJobName,
              chat_attribute_handStatus: this.personConfig.handStatus,
              chat_attribute_companyUserId: userInfo.id,
              chat_attribute_userId: this.personConfig.userId,
            },
          };
          if (type === 'loc') {
            let tempMarkers = [];
            tempMarkers.push({
              position: [content.lng, content.lat],
              icon: new AMap.Icon({
                image: location,
                size: new AMap.Size(40, 40),
                imageSize: new AMap.Size(40, 40),
              }),
              text: content.addDetail,
              offset: [0, -50],
            });
            let dataContent = {
              center: [content.lng, content.lat],
              markers: tempMarkers,
              window: {
                position: [content.lng, content.lat],
                isCustom: true,
                offset: [10, -40], // 窗体偏移
                visible: true, // 初始是否显示
                address: content.addName,
              },
              zoom: 14,
            };
            obj.data = dataContent;
            obj.ext.chat_attribute_address_detail = content.addDetail;
          } else if(type === 'img'){
            obj.data = content.url;
            obj.url = content.url;
          } else {
            obj.data = content;
          }
          // console.log("发送成功", "------obj = ", obj, "before add in record, this.recordContent = ", this.recordContent);
          // console.log(msgId, "-----", type, ", success.id = ", id);
          this.inputinfo = "";
          if(this.allRecoed["free_user_" + this.personConfig.userId]){
            this.allRecoed["free_user_" + this.personConfig.userId].push(obj);
            this.recordContent = [...this.recordContent, obj];
          } else {
            this.allRecoed["free_user_" + this.personConfig.userId] = [];
            this.allRecoed["free_user_" + this.personConfig.userId].push(obj);
            this.recordContent = this.allRecoed["free_user_" + this.personConfig.userId];
          }

          // 防止重复发送，去重
          const res = new Map();
          this.recordContent = this.recordContent.filter((a) => !res.has(a.time) && res.set(a.time, 1));

          // console.log("send", type, ", current record content = ", this.recordContent);
          setTimeout(() => {
            // this.getChatContent(this.liststuts);
            this.sending = false;
            this.refreshUserList(obj, true);
            this.$IM.refreshContentCatch();
            this.scrollToBottom();
          }, 0);
        },
      });
    },

    // toGet
    getUserList(list=[], toSwitchSelect){    // 更新用户列表，影响左侧列表
      // list[index]:
      //     "from":"1150201205042303#freemen_free_user_313@easemob.com/webim_1655370158190",
      //     "to":"1150201205042303#freemen_free_company_244@easemob.com",
      //     "id":"1021366593801685772",
      //     "timestamp":1655370241452,
      //     "payload":{}
      let temp = [];
      this.userInfoList = [];
      // console.log("refreshUserList --- list = ", list, ", current active = ", this.active);
      if (this.active === 1) {
        this.updateToUserInfoList(list, toSwitchSelect);
      } else if (this.active === 2) {
        list.map((item) => {
          if (item.unread_num > 0) {
            temp.push(item);
          }
        });
        this.updateToUserInfoList(temp, toSwitchSelect);
      } else if (this.active === 3) {
        // list.map((item) => {
        //   if (
        //       JSON.parse(item.meta.payload).ext.chat_attribute_handStatus === 1
        //   ) {
        //     temp.push(item);
        //   }
        // })
        this.updateToUserInfoList([], toSwitchSelect);
      } else if (this.active === 4) {
        this.$api.getOfferList("get", {status: 1}).then((sub) => {
          // console.log("-------in chat, $api.getOfferList.res = ", sub);
          let ids = [];
          sub.data.map((list) => {
            ids.push(list.userId);
          });
          list.map((item) => {
            if (
                ids.indexOf(
                    JSON.parse(item.meta.payload).ext.chat_attribute_userId
                ) !== -1
            ) {
              temp.push(item);
            }
          });
          this.updateToUserInfoList(temp, toSwitchSelect);
        });
      } else if (this.active === 5) {

        // console.log("---- check for talked list ### isNewAdd = ", this.newAddPersonInfo.isNewAdd);
        // console.log("---- check for talked list ### userDetailInfoListCache = ", this.userDetailInfoListCache);
        // 获取沟通列表
        if(this.newAddPersonInfo.isNewAdd || this.userDetailInfoListCache.length === 0){
          this.reloading = this.openLoading();
          this.$api.getTalkList("get").then((result) => {
            // console.log("Chat getTalkList.result = ", result.data);
            let tempUserInfo = result.data;
            temp = list;
            let ids = [];
            result.data.map((j, index) => {
              let flag = false;
              let config = {};
              ids.push({id: j.userId});
              temp.map((v) => {
                let tempId = JSON.parse(v.meta.payload).ext.chat_attribute_userId ? JSON.parse(v.meta.payload).ext.chat_attribute_userId
                    : JSON.parse(v.meta.payload).from.split("_")[1] === "user" ? JSON.parse(v.meta.payload).from.split("_")[2]
                        : JSON.parse(v.meta.payload).to.split("_")[2];
                if (tempId === j.user) {
                  flag = true;
                  config = {
                    id: tempId,
                    unread_num: v.unread_num,
                    timestamp: v.meta.timestamp,
                    msg_bodies: JSON.parse(v.meta.payload).bodies,
                  };
                }
              })
              if (flag) {
                tempUserInfo[index].msgInfo = config;
              } else {
                tempUserInfo[index].msgInfo = {
                  id: tempUserInfo[index].userId,
                  unread_num: 0,
                  timestamp: new Date(),
                  msg_bodies: [],
                }
              }

            });
            // console.log("Chat deal map tempInfoList = ", tempUserInfo);
            this.checkNewAdd(ids);
            // this.userInfoList = tempUserInfo;
            // 去重
            const res = new Map();
            // this.userInfoList = this.userInfoList.filter((a) => !res.has(a.userId) && res.set(a.userId, 1));
            this.userInfoList = tempUserInfo.filter((a) => !res.has(a.userId) && res.set(a.userId, 1));
            this.userDetailInfoListCache = this.userInfoList;
            // console.log("--------update the userInfoList, current = ", this.userInfoList);
            if (toSwitchSelect) {
              let tempIndex = 0;
              if (this.liststuts !== 0) {
                temp.map((item, index) => {
                  if (this.liststuts === item.userId) {
                    tempIndex = index;
                  }
                });
              }
              if (this.liststuts === this.userInfoList[tempIndex].userId) {
                this.getChatContent(this.liststuts);
              } else {
                this.personSelector(this.userInfoList[tempIndex], 1005);
              }
            } else {
              setTimeout(() => {
                this.getChatContent(this.liststuts);
                this.scrollToBottom();
              }, 0);
            }
            this.reloading.close();
          });
        } else {
          this.userInfoList = this.userDetailInfoListCache;
          // console.log("--------update the userInfoList by cache, current = ", this.userInfoList);
          if (toSwitchSelect) {
            let tempIndex = 0;
            if (this.liststuts !== 0) {
              temp.map((item, index) => {
                if (this.liststuts === item.userId) {
                  tempIndex = index;
                }
              });
            }
            if (this.liststuts === this.userInfoList[tempIndex].userId) {
              this.getChatContent(this.liststuts);
            } else {
              this.personSelector(this.userInfoList[tempIndex], 1005);
            }
          } else {
            setTimeout(() => {
              this.getChatContent(this.liststuts);
              this.scrollToBottom();
            }, 0);
          }
        }
      }
    },

    personSelector(personData, i){  // 点击对话列表事件
      // console.log("current listStatus=", this.liststuts,  ", personSelector", i + " - id = ", personData.userId, " :data-----------", personData);
      this.jobListVisible = false;
      // 刷新 jobList 在当前聊天对象中的状态
      // debugger
      if (personData.relatedCmpyJobIds) {
        if (personData.relatedCmpyJobIds.split(',').length > 1) {

          let relatedJobList = [];
          this.jobOption.selfJobList = this.jobOption.selfJobList.map((v) => {
            if (personData.relatedCmpyJobIds.indexOf(v.value) === -1) {
              v.related = false;
            } else {
              v.related = true;
              relatedJobList.push(v);
            }
            return v;
          });
          // 存在 一个以上 关联岗位是，放开 ‘切换岗位’ 功能
          if (relatedJobList.length > 1) {
            this.jobOption.flag = true;
            this.jobOption.relatedJobList = relatedJobList;
          } else {
            this.jobOption.flag = false;
          }

        } else {
          this.jobOption.flag = false;
        }
      }

      if (i === 1) {
        let id = this.liststuts === 0 ? this.liststuts : personData.userId;
        this.$IM.read({
          id: "free_user_" + id,
        });
        personData.msgInfo.unread_num = 0;
      }

      this.getChatContent(personData.userId);
      if(this.liststuts !== personData.userId){
        this.liststuts = personData.userId;
        // this.personConfig = personData;
      }
      this.checkPersonChatRecord(personData);
      // let config = {
      //   companyJobId: data.companyJobId ? data.companyJobId : data.chat_attribute_position_id,
      //   userJobId: data.userJobId ? data.userJobId : data.chat_attribute_job_intention_id,
      // };
      // this.$api.getCvdetail("get", config).then((res) => {
      //   console.log("update personConfig by api.getCvdetail.result = ", res, "----- AND current = ", this.current);
      //   this.personConfig = res.data;
      //   this.personConfig.moreShow = false;
      // });
    },
    // 检测用户联系方式是否授权
    checkPersonChatRecord(personData={}) {
      let temp = personData;
      // 获取当前用户聊天对象的联系方式 0: 未获取联系方式, 1: 已获取微信号, 2: 已获取手机号, 3: 全都已获取
      this.$api.getChatRecord("get", {userJobId: temp.userJobId, type: 1,})
          .then((res) => {
            // console.log("get wechat success, result = ", res);
            if (res.data != null && res.data.content != null) {
              temp.hasGotContactWay = 1;
              // this.wxStatus = 1;
              this.wxContent = res.data.content;
            } else {
              this.wxStatus = -1;
              temp.hasGotContactWay = 0;
            }
            this.$api.getChatRecord("get", {userJobId: temp.userJobId, type: 2,})
                .then((res) => {
                  // console.log("get phone success, result = ", res);
                  if (res.data != null && res.data.content != null) {
                    temp.hasGotContactWay = temp.hasGotContactWay === 1 ? 3 : 2;
                    // this.phoneStatus = 1;
                    this.phoneContent = res.data.content;
                  } else {
                    this.phoneStatus = -1;
                    temp.hasGotContactWay = temp.hasGotContactWay === 1 ? 1 : 0;
                  }
                  this.personConfig = temp;
                  this.personConfig.moreShow = false;
                })
          });
    },
    getChatContent(id){    // 获取对话信息 "free_user_"+id
      // console.log("getChatContent id = ", id, "current all Record = ", this.allRecoed);
      this.recordContent = [];
      if (this.allRecoed["free_user_" + id]) {
        this.recordContent = this.allRecoed["free_user_" + id];
        // console.log("getContext current record content from allRecord = ", this.recordContent);
        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
      } else {
        this.$IM.getContent({
          id: "free_user_" + id,
          success: (res) => {
            // console.log("getChatContext.IM.getContent id = free_user_", id, " res = ", res);
            this.allRecoed["free_user_" + id] = [];
            if(res && res.length > 0){
              this.allRecoed["free_user_" + id] = this.dealMessage2Content(res);
              this.recordContent = this.allRecoed["free_user_" + id];
              // console.log("getContext.getContent current record content = ", this.recordContent);
              setTimeout(() => {
                this.scrollToBottom();
              }, 0);
            }
          },
        });
      }
    },
    dealMessage2Content(message) {  // 整理IM返回消息结构，处理并入聊天记录content
      // console.log("deal RecordContent, message = ", message, ", Array.isArray(message) = ", Array.isArray(message));
      let tempUserId = -1;
      if(Array.isArray(message)){
        let configs = [];
        message.map((item) => {
          if (item.ext.chat_attribute_userId) {
            if (item.from.split('_')[1] === 'user') {
              tempUserId = item.from.split('_')[2];
            } else {
              tempUserId = item.to.split('_')[2];
            }
          } else {
            tempUserId = item.ext.chat_attribute_userId;
          }
          let dataType = item.contentsType ? item.contentsType
              : item.ext.chat_attribute_type ? item.ext.chat_attribute_type
                  : item.type;
          let dataContent;
          if (dataType === 'loc' || dataType === 'LOCATION') {
            let tempMarkers = [];
            tempMarkers.push({
              position: [item.lng, item.lat],
              icon: new AMap.Icon({
                image: location,
                size: new AMap.Size(40, 40),
                imageSize: new AMap.Size(40, 40),
              }),
              text: item.ext.chat_attribute_address_detail,
              offset: [0, -50],
            });
            dataContent = {
              center: [item.lng, item.lat],
              markers: tempMarkers,
              window: {
                position: [item.lng, item.lat],
                isCustom: true,
                offset: [10, -40], // 窗体偏移
                visible: true, // 初始是否显示
                address: item.addr,
              },
              zoom: 14,
            };
          } else if (dataType === 'custom' || dataType === 'CUSTOM') {
            dataContent = item.customEvent;
          } else {
            dataContent = item.data;
          }
          let config = {
            id: item.id,
            userId: tempUserId,
            type: dataType,
            time: item.time,
            visible: false,
            from: item.from,
            to: item.to,
            data: dataContent,
            url: item.url ? item.url : "",
            ext: item.ext,
          }
          // console.log("update the record content, config = ", config);
          configs.push(config);
        });
        return configs;
      } else {
        if (message.ext !== undefined || message.ext.chat_attribute_userId) {
          if (message.from.split('_')[1] === 'user') {
            tempUserId = message.from.split('_')[2];
          } else {
            tempUserId = message.to.split('_')[2];
          }
        } else {
          tempUserId = message.ext.chat_attribute_userId;
        }
        let dataType = message.contentsType ? message.contentsType
            : message.ext.chat_attribute_type ? message.ext.chat_attribute_type
                : message.type;
        let dataContent;
        if (dataType === 'loc') {
          let tempMarkers = [];
          tempMarkers.push({
            position: [message.lng, message.lat],
            icon: new AMap.Icon({
              image: location,
              size: new AMap.Size(40, 40),
              imageSize: new AMap.Size(40, 40),
            }),
            text: message.ext.chat_attribute_address_detail,
            offset: [0, -50],
          });
          dataContent = {
            center: [message.lng, message.lat],
            markers: tempMarkers,
            window: {
              position: [message.lng, message.lat],
              isCustom: true,
              offset: [10, -40], // 窗体偏移
              visible: true, // 初始是否显示
              address: message.addr,
            },
            zoom: 14,
          };
        } else if (dataType === 'custom' || dataType === 'CUSTOM') {
          dataContent = message.customEvent;
        } else {
          dataContent = message.data;
        }
        let config = {
          id: message.id,
          userId: tempUserId,
          type: dataType,
          time: message.time,
          visible: false,
          from: message.from,
          to: message.to,
          data: dataContent,
          url: message.url ? message.url : "",
          ext: message.ext,
        }
        return config;
      }
    },

    // #########################################
    onReceiveToDealRecall(message){
          // "id": "1026178173651388480",
          // "from": "free_user_313",
          // "to": "free_company_244",
          // "mid": "1026178109495314496",
          // "onlineState": 3
      let currentId = this.liststuts + "";
      if(this.allRecoed[message.from]){
        // let recallContent = { }
        this.allRecoed[message.from].map((v, index) =>{
            if(v.id === message.mid){
              let temp = v;
              temp.data = "###freeman-message-recall-content###";
              this.allRecoed[message.from][index] = temp;
            }
        });
      }
      if(currentId === message.from.split('_')[2]) {
        this.recordContent = this.allRecoed[message.from];
      }
    },
    onReceiveToUpdateMessage(message){         // 本地数组中新增 这条新消息
      let tempContent = this.dealMessage2Content(message);
      let currentId = this.liststuts + "";
      // console.log("onReceiveToUpdateMessage------ content = ", tempContent, ", ### this.all = ", this.allRecoed);
      if (this.allRecoed["free_user_"+tempContent.userId]) {
        this.allRecoed["free_user_"+tempContent.userId].push(tempContent);
        if(currentId === tempContent.userId) {
          // console.log("onReceiveToUpdateMessage--this.allRecoed['free_user_'",tempContent.userId, "]---- check to update content = ", this.liststuts);
          this.recordContent = [...this.recordContent, tempContent];
        }
      } else {
        this.allRecoed["free_user_"+tempContent.userId] = [];
        this.allRecoed["free_user_"+tempContent.userId].push(tempContent);
        if(currentId === tempContent.userId){
          // console.log("onReceiveToUpdateMessage------ check to update content = ", this.liststuts);
          this.recordContent = this.allRecoed["free_user_"+tempContent.userId];
        }
      }
      // 防止重复发送，去重
      const res = new Map();
      this.recordContent = this.recordContent.filter((a) => !res.has(a.time) && res.set(a.time, 1));
    },
     onContextmenu(event, data, type) {
       // console.log("onContextmenu, current event=", event, ", current data = ", data);
      if(type === 0){
        this.$contextmenu({
          items: [
            {
              label: "标记未读",
              disabled: data.msgInfo.unread_num,
              divided: true,
              onClick: () => {
                // console.log("key = markRead", this.userInfoList);
                this.userInfoList = this.userInfoList.map((v) => {
                  const id = v.msgInfo.id;
                  let tempMsgInfo = id === data.msgInfo.id ? { ...v.msgInfo, unread_num: 1 } : v.msgInfo;
                  v.msgInfo = tempMsgInfo;
                  return v;
                });
                // console.log("标记未读, 当前用户列表为", this.userInfoList);
              }
            },
            { label: "标记已读",
              disabled: !data.msgInfo.unread_num,
              divided: true,
              onClick:() => {
                // console.log("key = unread", this.userInfoList);
                this.getIMList(false);
              }
            },
            { label: "删除会话",
              onClick:() => {
                // console.log("key = del", this.userInfoList);
                let userId = "free_user_"+ data.userId;
                this.newAddPersonInfo.isNewAdd = false;
                this.$IM.deleteSession({userID: userId,}).then(() => {
                      console.log("删除会话成功!");
                      this.getIMList(true);
                    });
              }
            },
          ],
          event,
          customClass: "custom-class", // 自定义菜单 class
          zIndex: 3, // 菜单样式 z-index
          minWidth: 130 // 主菜单最小宽度
        });
      } else {
        this.$contextmenu({
          item : [
            {
              label: "撤回",
              disabled: false,
              divided: true,
              onClick: () => {
                // this.mouseclick(data);
              }
            },
          ],
          event,
          customClass: "custom-class", // 自定义菜单 class
          zIndex: 3, // 菜单样式 z-index
          minWidth: 58 // 主菜单最小宽度
        });
      }
    },

    // #########################################
    updateUserList(arr=[]){  //  更新用户列表, 同步ext参数结构
       // from - 自己company, to - 对方user
      // [0]:
      // {
      //   "channel_id":"1150201205042303#freemen_free_user_324@easemob.com",
      //   "unread_num":1,
      //   "meta":{
      //       "from":"1150201205042303#freemen_free_user_324@easemob.com/android_0485af67-f5b0-395e-9014-c44f546da013",
      //       "to":"1150201205042303#freemen_free_company_268@easemob.com",
      //       "id":"1021266506459121668",
      //       "timestamp":1655346938042,
      //       "payload":"{\"bodies\":[{\"msg\":\"qqqqwer\",\"type\":\"txt\"}],\"ext\":{\"chat_attribute_avatar_from\":\"files/20220601/IMG_CROP_20220601095914499_1654048758156.png\",\"chat_attribute_company_id\":137,\"chat_attribute_company_name\":\"\",\"chat_attribute_gender_from\":1,\"chat_attribute_gender_to\":1,\"chat_attribute_job_intention_id\":512,\"chat_attribute_nickname_from\":\"kanthy\",\"chat_attribute_nickname_to\":\"好好上课\",\"chat_attribute_position_id\":447,\"chat_attribute_position_name\":\"Java开发工程师\"},\"from\":\"free_user_324\",\"meta\":{},\"to\":\"free_company_268\",\"type\":\"chat\"}"
      //     }
      // },
      // console.log("update the userList, syns the ext struct-------- current = ", this.current, "-------- personConfig = ", this.personConfig);
      let tempList = [];
      for(var i = 0; i<arr.length; i++){
        let temp = JSON.parse(arr[i].meta.payload);
        // let isFromCompany = temp.from == "free_company_"+this.current.id;
        let tempPayload = {
          bodies: temp.bodies,
          from: temp.from,
          to: temp.to,
          ext: {
            chat_attribute_avatar_from: temp.ext.chat_attribute_avatar_from?temp.ext.chat_attribute_avatar_from:this.current.avatar,
            chat_attribute_avatar_to:temp.ext.chat_attribute_avatar_to?temp.ext.chat_attribute_avatar_from:this.personConfig.avatar,
            chat_attribute_company_id: temp.ext.chat_attribute_company_id,
            chat_attribute_company_name: temp.ext.chat_attribute_company_name,
            chat_attribute_gender_from: temp.ext.chat_attribute_gender_from,
            chat_attribute_gender_to: temp.ext.chat_attribute_gender_to,
            chat_attribute_job_intention_id: temp.ext.chat_attribute_job_intention_id,
            chat_attribute_nickname_from: temp.ext.chat_attribute_nickname_from,
            chat_attribute_nickname_to: temp.ext.chat_attribute_nickname_to,
            chat_attribute_position_id: temp.ext.chat_attribute_position_id,
            chat_attribute_position_name: temp.ext.chat_attribute_position_name,
            chat_attribute_type: temp.ext.chat_attribute_type ? temp.ext.chat_attribute_type : temp.bodies[0].type,
            chat_attribute_handStatus: temp.ext.chat_attribute_handStatus ? temp.ext.chat_attribute_handStatus : 0,
            chat_attribute_companyUserId: temp.ext.chat_attribute_companyUserId ? temp.ext.chat_attribute_companyUserId
                                            : temp.from.split('_')[1]=="company" ? temp.from.split('_')[2] : temp.to.split('_')[2],
            chat_attribute_userId: temp.ext.chat_attribute_userId ? temp.ext.chat_attribute_userId
                                            : temp.from.split('_')[1]=="user" ? temp.from.split('_')[2] : temp.to.split('_')[2],
          }
        }
        arr[i].meta.payload = tempPayload;
        tempList.push(arr[i]);
      }
      this.userList = tempList;
      // console.log("updateUserList------ this.userlist = ", this.userList);
    },
    /* 分类判断消息类型：
    *   1：企业方撤回消息, 2：个人方撤回消息, 0：不是撤回
    *   3：自己发送的微信号码申请, 4:自己发送的手机号码申请,
    *   5:技术者返回拒绝微信申请, 6:技术者返回接受微信申请, 7:技术者返回拒绝手机申请, 8:技术者返回接受手机申请
    **/
    messageSort(item) {
      // console.log("isRecall", item);
      let v1 = item.data === '###freeman-message-recall-content###';   // 判断是否为 撤回消息
      let v2 = item.from === "free_company_" + this.current.id;        // 判断消息来源为发送还是接收

      if(v1){  // 是撤回消息
        if(v2) return 1;
        if(!v2) return 2;
      }else{   // 不是撤回消息 back > 2
        // console.log("------------messageSort, current data = ", item, "--------------------");
        if(item.data === "cmpy_get_wechat" && this.personConfig.hasGotContactWay !== 1 && this.personConfig.hasGotContactWay !==3) return 3;
        if(item.data === "cmpy_get_phone"&& this.personConfig.hasGotContactWay !== 2 && this.personConfig.hasGotContactWay !==3) return 4;
        if(item.data === "tech_reject_get_wechat" && this.personConfig.hasGotContactWay !== 1 && this.personConfig.hasGotContactWay !==3)  return 5;
        if(item.data === "tech_agree_get_wechat" && this.personConfig.hasGotContactWay !== 1 && this.personConfig.hasGotContactWay !==3)  return 6;
        if(item.data === "tech_reject_get_phone" && this.personConfig.hasGotContactWay !== 2 && this.personConfig.hasGotContactWay !==3)  return 7;
        if(item.data === "tech_agree_get_phone" && this.personConfig.hasGotContactWay !== 2 && this.personConfig.hasGotContactWay !==3)  return 8;
        if(item.type === 'txt' || item.type === 'TEXT') return 11;  // 不是获取联系方式相关自定义消息 back > 10
        if(item.type === 'img' || item.type === 'IMAGE') return 12;
        // if(item.type === 'custom' || item.type === "CUSTOM") return 13;
        if(item.type === 'loc' || item.type === 'LOCATION') return 14;
        return 0;
      }
      return -1
    },
    contextMenuOption(event, item = {}) {
      // debugger
      // console.log("current event=", event, "menuOption---------", item);
      this.visibleShow = true;
      this.recordContent = this.recordContent.map((v) =>
          v.id === item.id && Date.now() - item.time < 2 * 60 * 1000
              ? { ...v, visible: true }
              : { ...v, visible: false }
      );
    },
    closeMenu(){
      this.visibleShow = false;
    },
    toInterview() {
      //面试邀约
      // console.log("面试邀约 ##### ---- this.personConfig", this.personConfig);
      this.$interview.show({
        show: true,
        jobList: this.jobOption.selfJobList,
        userInfo: this.personConfig,
        selected: this.personConfig.companyJobId,
      });
    },
    getContactWay(msg){
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      // 自定义消息
      this.sendMessage(userInfo, msg, 'custom');
    },
    handleSuccess(response, file, fileList) {
      //上传成功
      this.tipStatus = true;
      console.log(response, file, fileList);
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let imgUrl = response.data != ""
          ? "https://freemen.work/user/file/view/" + response.data
          : "https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png";
      var img = new Image();
      let res = {};
        img.src = imgUrl;
        img.onload= function () {
          res = {
            url: imgUrl,
            width: img.width,
            height: img.height
          }
          // console.log(res.url,'width:'+res.width+',height:'+res.height);
        }

      clearTimeout(tempTimer);
      console.log("等待图片加载...");
      tempTimer = setTimeout(() => {
        if (res.width && res.height) {
          // console.log('width:'+res.width+',height:'+res.height);
          this.sendMessage(userInfo, res, 'img');
        } else {
          setTimeout(() => {
            // console.log('image loading, width:'+res.width+',height:'+res.height);
            this.sendMessage(userInfo, res, 'img');
          }, 350)
        }
        this.tipStatus = false;
      }, 750);
    },
    mouseclick(data) { // 消息撤回 点击方法
      // console.log("before mouse click, data =", data);
      const { id, userId} = data;
      const delFn = () => {
        const key = "free_user_" + userId;
        this.recordContent.map((v, index) => {
          if(v.id === id){
            this.recordContent.splice(index, 1);
          }
        });
        console.info('mouseclick defFN', this.recordContent)
        this.allRecoed = {
          ...this.allRecoed,
          [key]: this.recordContent,
        };
      };
      // console.log("before mouse click, id=", id);
      this.$IM.recallMessage({
        mid: id,
        ...data,
        success: () => {
          delFn();
          // 发送一段消息作为消息撤回的占位符
          let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
          // console.log("sendRecallMes user info = ", userInfo);
          this.sendMessage(userInfo, "###freeman-message-recall-content###", "txt");
        },
        fail: (e) => {
          console.log("撤回失败! ", e);
        },
      });
    },
    download() {
      this.jlstuts = !this.jlstuts;
      // this.$router.push({
      //   path: "/previewresume",
      //   query: {
      //     companyJobId: this.current.companyJobId,
      //     userJobId: this.current.userJobId,
      //   },
      // });
      window.open(
        window.location.origin +
          `/#/previewresume?companyJobId=${this.personConfig.companyJobId}&userJobId=${this.personConfig.userJobId}`
      );
    },
    tipChange(value) {
      for (const tip of this.tips) {
        // console.log("tipChange",tip);
        if (value === tip.id) {
          this.workForm.street = tip.district + tip.address + tip.name;
          let markers = [];
          let windows = [];
          if (!tip.location) return;
          let basePosition = [tip.location.lng, tip.location.lat];
          // let icon = new AMap.Icon({
          // 	image: location,
          // 	size: new AMap.Size(2, 2),
          // 	imageSize: new AMap.Size(2, 2)
          // });
          // content添加相应的内容**********
          markers.push({
            position: basePosition,
            icon: new AMap.Icon({
              image: location,
              size: new AMap.Size(40, 40),
              imageSize: new AMap.Size(40, 40),
            }),
            text: "content1111",
            offset: [0, -50],
          });
          windows.push({
            position: basePosition,
            isCustom: true,
            offset: [10, -40], // 窗体偏移
            visible: true, // 初始是否显示
            address: tip.name,
          });
          this.zoom = 15;
          this.markers = markers;
          this.windows = windows;
          this.center = [tip.location.lng, tip.location.lat];
          this.window = windows[0];
          break;
        }
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const that = this;
        AMap.plugin("AMap.Autocomplete", function () {
          // 实例化Autocomplete
          const autoOptions = {
            city: "全国",
          };
          const autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search(query, function (status, result) {
            // console.log("remoteMethod", result);
            // 搜索成功时，result即是对应的匹配数据
            that.tips = result.tips || [];
            that.loading = false;
          });
        });
      } else {
        this.tips = [];
      }
    },
    closeworkDialogbox() {
      this.workDialogVisible = false;
    },
    scrollToBottom() {
      // this.$refs.chatbox.scrollTop = 999999999;
      const chatbox = this.$refs.chatbox;
      if(!chatbox) return;
      // console.log("scrollToBottom, height", chatbox.scrollHeight);
      chatbox.scrollTop = chatbox.scrollHeight;
    },

    datatime(value) {
      var data = new Date(value);
      var month =
        data.getMonth() < 9 ? "0" + (data.getMonth() + 1) : data.getMonth() + 1;
      var date = data.getDate() <= 9 ? "0" + data.getDate() : data.getDate();
      return data.getFullYear() + "-" + month + "-" + date;
    },
    getMoreWork(data) {
      //展示所有工作经历
      data.moreShow = !data.moreShow;
      this.$forceUpdate();
    },
    // 视频详情
    videoshow() {
      let config = {
        show: true,
        data: this.personConfig,
      };
      this.$videoDetail.show(config);
    },
    //获取手机号
    getPhone() {
      if(this.phoneStatus === -1){
        // 未获取过手机号码
        this.$api.getChatRecord("get", {
          userJobId: this.personConfig.userJobId,
          type: 2,
        }).then((res) => {
          this.emoji = false;
          this.langstuts = false;
          this.jlstuts = false;
          // console.log("get phone success, result = " , res);
          if(res.data != null && res.data.content != null){
            this.phoneStatus = 1;
            this.phoneContent = res.data.content;
            setTimeout(() => {
              this.scrollToBottom();
            }, 0);
          } else {
            this.phoneStatus = 0;
            this.getContactWay("cmpy_get_phone");
          }
        });
      } else if (this.phoneStatus === 0) {
        // 已发送过手机号申请
        this.$message({
          message: "已经发送手机号获取申请，请耐心等待技术者的答复！",
          type: "warning",
        });
      } else {
        // 额外判断, 手机号内容是否为真
        if(this.phoneContent !== ""){
          this.phoneStatus = 1;
        } else {
          // 重新执行远程获取
          this.$api.getChatRecord("get", {
            userJobId: this.personConfig.userJobId,
            type: 2,
          }).then((res) => {
            // console.log("get phone success, result = " , res);
            if(res.data != null && res.data.content != null){
              this.phoneStatus = 1;
              this.phoneContent = res.data.content;
              setTimeout(() => {
                this.scrollToBottom();
              }, 0);
            }
          });
        }
      }
    },
    //获取微信号
    getWx() {
      if (this.wxStatus === -1) {

        this.$api.getChatRecord("get", {
          userJobId: this.personConfig.userJobId,
          type: 1,
        }).then((res) => {
          this.emoji = false;
          this.langstuts = false;
          this.jlstuts = false;
          // console.log("get WX success, result = ", res);
          if (res.data != null && res.data.content != null) {
            this.wxStatus = 1;
            this.wxContent = res.data.content;
            setTimeout(() => {
              this.scrollToBottom();
            }, 0);
          } else {
            this.wxStatus = 0;
            this.wxContent = "";
            this.getContactWay("cmpy_get_wechat");
          }
        });
      } else if (this.wxStatus === 0){

        this.$message({
          message: "已经发送微信号获取申请，请耐心等待技术者的答复！",
          type: "warning",
        });
      } else {

        if(this.wxContent !== ""){
          this.wxStatus = 1;
        } else {

          this.$api.getChatRecord("get", {
            userJobId: this.personConfig.userJobId,
            type: 1,
          }).then((res) => {
            if (res.data != null && res.data.content != null) {
              this.wxStatus = 1;
              this.wxContent = res.data.content;
              setTimeout(() => {
                this.scrollToBottom();
              }, 0);
            }
          });
        }
      }

    },
    submitForm(formName) {
      // console.log("submit, current window.address = ", this.windows)
      this.$refs[formName].validate((valid) => {
        // console.log("submitForm current map info, valid=", valid );
        if (valid) {
          let addressInfo = {
            addName: this.window.address,
            addDetail: this.workForm.street + this.workForm.housenumber,
            lng: this.center[0],
            lat: this.center[1],
          }
          this.workDialogVisible = false;
          // console.log("submitForm current map info, workForm=", this.workForm );
          // console.log("submitForm current map info, addressInfo=", addressInfo );
          let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
          // this.sendTextMessage(userInfo, addressInfo, "loc");
          this.sendMessage(userInfo, addressInfo, 'loc');
        } else {
          return false;
        }
      });
    },
    emojiClick(data) {
      //表情点击
      this.emoji = false;
      let input = document.getElementById("input");
      let startnum = input.selectionStart;
      if (startnum === undefined) {
        startnum = 0;
      }
      this.inputinfo =
        this.inputinfo.substring(0, startnum) +
        data +
        this.inputinfo.substring(startnum);
      input.focus();
      input.selectionStart = startnum + 1;
    },
    lanSet() {
      //常用语设置
      this.langstuts = !this.langstuts;
      this.$router.push("commonwords");
    },
    lanClick(data) {
      //常用语点击
      this.langstuts = !this.langstuts;
      let input = document.getElementById("input");
      let startnum = input.selectionStart;
      if (startnum === undefined) {
        startnum = 0;
      }
      this.inputinfo =
        this.inputinfo.substring(0, startnum) +
        data.content +
        this.inputinfo.substring(startnum);
      input.focus();
      input.selectionStart = startnum + data.content.length;
    },
    getAdress() {
      this.emoji = false;
      this.langstuts = false;
      this.jlstuts = false;
      const self = this;
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);
        function onComplete(data) {
          // data是具体的定位信息
          console.log("定位成功信息：", data);
          this.inputinfo = data.formattedAddress;
        }
        function onError(data) {
          // 定位出错
          console.log("定位失败错误：", data);
          // 调用ip定位
          self.getLngLatLocation();
        }
      });
      this.workDialogVisible = true;
    },
    getLngLatLocation() {
      let that = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          // console.log(status, result.info);
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            console.log("通过ip获取当前城市：", result);
            //逆向地理编码
            AMap.plugin("AMap.Geocoder", function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode,
              });
              var lnglat = result.rectangle.split(";")[0].split(",");
              geocoder.getAddress(lnglat, function (status, data) {
                if (status === "complete" && data.info === "OK") {
                  // result为对应的地理位置详细信息
                  // console.log("getAddress", data);
                  that.inputinfo = data.formattedAddress;
                }
              });
            });
          }
        });
      });
    },
    sendMes() {
      this.inputinfo = this.inputinfo.replace(/\s/g,"");
      // console.log("OnMessageSending ----- inputInfo = ", this.inputinfo);
      //发送文本消息
      if (!this.inputinfo || this.inputinfo.length < 1) {
        this.$message({
          message: "不要发送空白信息",
          type: "error",
        });
        return;
      }
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.sendMessage(userInfo, this.inputinfo, 'txt');
    },
    rightClick() {
      //右键标记已读
      this.dustuts = true;
    },
    changenum(vals) {
      //对话，在线简历切换
      this.activenum = vals;
      setTimeout(() => {
        this.scrollToBottom();
      }, 50);
    },
    toyiwoshou() {
      //邀请面试
      this.emoji = false;
      this.langstuts = false;
      this.jlstuts = false;
      this.$router.push("/handshake");
    },
    getTime(shijianchuo) {
      this.$moment.locale("zh-cn");
      return this.$moment
        .unix(shijianchuo / 1000)
        .startOf("minute")
        .fromNow();
    },
    openLoading() {
      const loading = this.$loading({ // 声明一个loading对象
        target: document.querySelector('#main'),
        lock: false, // 是否锁屏
        text: '加载中，请稍候. . . ', // 加载动画的文字
        background: 'rgba(0, 0, 0, 0.7)' // 背景颜色
      })
      setTimeout(function() { // 设定定时器，超时2S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
        loading.close() // 关闭遮罩层
      }, 2000)
      return loading
    },
    changeSelfJob(job){
      // console.log("@changeSelfJob ----- job = ", job);
      this.personConfig.companyJobName = job.label;
      this.personConfig.companyJobId = job.value;
      this.jobListVisible = false;
    },
  },
};
</script>
<style lang="less">
.c-caozuo {
  .el-upload {
    > img {
      margin-top: 5px;
    }
  }
  .el-upload-list {
    display: none !important;
  }
}
.popper {
  min-width: 50px !important;
  padding: 0;
}
.user-list.m-active:focus{
  outline: none!important;
}
</style>
<style lang="less" scoped>
#info-window {
  padding: 0px 20px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
#msg-from-window {
  padding: 0px 20px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
#msg-to-window {
  padding: 0px 20px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.tipInput {
  width: 300px;
}
.amap-wrapper {
  width: 500px;
  height: 250px;
  margin-bottom: 20px;
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.work-box-out {
  .work-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      padding: 50px 60px 30px 60px;
      .demo-ruleForm {
        button {
          width: 160px;
          height: 40px;
          background-color: #00bcff;
        }
        .qux {
          background-color: #fff;
          border: 1px solid #00bcff;
          color: #00bcff;
        }
      }
    }
  }
}
.nodata {
  background-color: #ffffff;
  // box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0 96px 0;
  margin-top: 20px;
  div {
    color: #111111;
    margin-top: 20px;
  }

  img {
    width: 260px;
    height: 220px;
  }
}
.infobox {
  padding: 40px 30px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
  .person-info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 20px;
    .p-picurl {
      margin-right: 20px;
      position: relative;
      img {
        width: 50px;
        height: 50px;
      }
      .bofang {
        position: absolute;
        top: -5px;
        right: -5px;
        border-radius: 50px;
        color: #3f3f3f;
      }
    }
    .p-news {
      display: flex;
      flex: 1;
      flex-direction: column;
      line-height: 1.6;
      .pn-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .namemain {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #111111;
          img {
            width: 24px;
            height: 24px;
            margin: 0 18px 0 10px;
          }
          span:last-child {
            background-color: #e9f9ff;
            color: #00bcff;
            padding: 2px 10px;
          }
        }
        .score {
          background-color: #00bcff;
          border-radius: 8px 8px 8px 0;
          color: #ffffff;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .beizhu {
        color: #666666;
      }
    }
  }
  .erji-tit {
    margin: 20px 0 20px 0;
    color: #111111;
  }
  .worktime {
    .time-main {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 20px;
      border-bottom: 1px solid #f3f3f3;
      img {
        width: 50px;
        height: 50px;
        margin-right: 5px;
      }
      span {
        margin: 0 30px;
      }
    }
  }
  .intention {
    .workinfo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #111111;
      font-weight: 600;
      span {
        color: #666666;
        margin-left: 10px;
        font-weight: 500;
      }
    }
    .workbiaoqian {
      padding: 20px 0;
      border-bottom: 1px solid #f3f3f3;
      display: flex;
      flex-wrap: wrap;
      span {
        background-color: #eeeeee;
        padding: 4px 8px;
        margin: 0 10px 10px 0;
        color: #666666;
      }
    }
    .workexperience {
      .experiencelist {
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 15px;
        margin-top: 30px;
        .gs-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .gi-left {
            color: #111111;
            span {
              color: #666666;
              margin-left: 15px;
            }
          }
          .gi-right {
            color: #111111;
          }
        }
        .workbiaoqian {
          padding: 20px 0;
          border-bottom: 1px solid #f3f3f3;
          display: flex;
          flex-wrap: wrap;
          span {
            background-color: #eeeeee;
            padding: 4px 8px;
            margin: 0 10px 10px 0;
            color: #666666;
          }
        }
        .messageall {
          margin-top: 20px;
          color: #333333;
          line-height: 2;
        }
      }
    }
    .education {
      // border-bottom: 1px solid #f3f3f3;
      padding-bottom: 20px;
      .education-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #111111;
        .em-left {
          color: #333;
        }
        .em-right {
        }
      }
    }
    .btn-caozuo {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      /deep/ .el-button {
        border-radius: 0px;
        background-color: #00bcff;
        border: none;
      }

      .yy {
        width: 350px;
      }
    }
  }
}
.chatall {
  width: 100%;
  height: 80%;
  background-color: #ffffff;
  display: flex;

  .chat-left {
    width: 308px;
    height: 100%;
    flex-shrink: 0;
    background-color: #e7e6e7;
    .search-box {
      height: 50px;
      //padding-bottom: 3px;
      padding-left: 3px;
      //padding-top: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      /deep/ .el-input__inner {
        border-radius: 0px;
        background-color: #e7e6e7;
        border: none;
      }
    }
    .msg-list {
      .ml-tit {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #111111;
        padding-left: 10px;
        overflow: hidden;
      }
      .ul-box {
        // height: 370px;
        overflow: auto;
      }
      .ul-box::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 8px;
        height: 1px;
      }
      .ul-box::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 4px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #a0a0a0;
      }
      .m-active {
        background-color: #cac8c6 !important;
      }
      .user-list:hover {
        cursor: pointer;
        background-color: #cac8c6 !important;
      }
      .user-list {
        height: 60px;
        // background-color: #000080;
        padding: 6px 10px;
        display: flex;
        align-items: center;
        position: relative;
        .nodu {
          width: 150px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 4px;
          position: absolute;
          bottom: -12px;
          right: 18px;
          color: #111111;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 999;
        }
        .ul-pic {
          width: 50px;
          img {
            width: 50px;
            height: 50px;
            border-radius: 50px;
          }
          margin-right: 10px;
        }
        .msg-info {
          display: flex;
          flex: 1;
          flex-direction: column;
          line-height: 1.6;
          .mi-one {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name {
              color: #111111;
              position: relative;
              span {
                background-color: #ff4444;
                border-radius: 10px;
                width: 18px;
                height: 18px;
                position: absolute;
                top: -6px;
                right: -10px;
                color: #ffffff;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .time {
              color: #999999;
            }
          }
          .mi-two {
            color: #666666;
            width: 152px;
            overflow: hidden;
            font-size: large;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .chat-right {
    // display: flex;
    // flex: 1;
    height: 100%;
    flex-grow: 1;
    background-color: #ffffff;
    .youce-look {
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 184px;
        height: 178px;
      }
      div {
        color: #111111;
        font-size: 20px;
        margin-top: 50px;
      }
    }
  }
}
.chat-main {
  //width: 926px;
  .tab-box {
    height: 50px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    .tb-main:hover {
      cursor: pointer;
    }
    .tb-main {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: #111111;
      font-size: 20px;
      height: 100%;
    }
    .tb-active {
      color: #00bcff;
      position: relative;
    }
    .tb-active:after {
      position: absolute;
      content: "";
      width: 90px;
      height: 4px;
      bottom: -2px;
      background: #00bcff;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.qy-info {
  // height: 370px;
  overflow: auto;
}
.qy-info::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px;
  height: 1px;
}
.qy-info::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #a0a0a0;
}
.qy-info {
  padding: 30px 20px;
}
.chat-header {
  padding: 18px 30px 10px 30px;
  // border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .c-name {
    color: #111111;
    margin-right: 10px;
  }
  img {
    width: 20px;
    height: 20px;
  }
  .gs-name {
    color: #999999;
    margin-left: 20px;
  }
}
.baioti {
  color: #999999;
  padding: 10px 30px;
  background-color: #ffffff;
  span {
    color: #00bcff;
    margin-left: 6px;
  }
}
.chat-content {
  // height: 370px;
  overflow: auto;
}
.chat-content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px;
  height: 1px;
}
.chat-content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #a0a0a0;
}
.chat-content {
  // width: 100%;
  padding: 0 30px 20px 30px;
  .list-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
    .person-info {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 20px;
      .p-picurl {
        margin-right: 20px;
        position: relative;
        img {
          width: 50px;
          height: 50px;
        }
        .bofang {
          position: absolute;
          top: -5px;
          right: -5px;
          border-radius: 50px;
          color: #3f3f3f;
        }
      }
      .p-news {
        display: flex;
        flex: 1;
        flex-direction: column;
        line-height: 1.6;
        .pn-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .namemain {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img {
              width: 24px;
              height: 24px;
              margin: 0 18px 0 10px;
            }
            span:last-child {
              background-color: #e9f9ff;
              color: #00bcff;
              padding: 2px 10px;
            }
          }
          .score {
            background-color: #00bcff;
            border-radius: 8px 8px 8px 0;
            color: #ffffff;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .beizhu {
          color: #666666;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            margin-right: 48px;
          }
        }
      }
    }
    .transitionbox {
      .t-one {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #666666;
        margin-top: 20px;
        img {
          width: 22px;
          height: 20px;
        }
        span {
          margin: 0 30px 0 10px;
        }
      }
    }
    .label-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .li-left {
        span {
          background-color: #eeeeee;
          padding: 4px 8px;
          margin-right: 10px;
          color: #666666;
        }
      }
      .li-right {
        display: flex;
        align-items: center;
        /deep/ .el-button {
          border-radius: 0px;
          height: 40px;
          margin-left: 30px;
          border: none;
        }
        .gt {
          background-color: #00bcff;
        }
        .yy {
          background-color: #ffa544;
        }
      }
    }
  }
  .word {
    .c-time {
      // background-color: #000080;
      text-align: center;
      color: #999999;
      font-size: 14px;
      margin-bottom: 20px;
    }
    .infoboxes {
      display: flex;
      margin-bottom: 20px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .info {
        margin-left: 10px;
        .pic_content {
          width: 80px;
          height: 80px;
          border-radius: 0;
        }
        .loc_content {
          width: 80%;
          border-radius: 0;
        }
        .time {
          font-size: 12px;
          color: rgba(51, 51, 51, 0.8);
          margin: 0;
          height: 20px;
          line-height: 20px;
          margin-top: -5px;
        }
        .info-content {
          padding: 10px;
          font-size: 14px;
          background: #fff;
          position: relative;
          margin-top: 4px;
          background-color: #f3f3f3;
          border-radius: 0px 20px 0px 20px;
          word-break:break-all;
          word-wrap:break-word;
          white-space:pre-wrap;
        }
        //小三角形
        // .info-content::before {
        // 	position: absolute;
        // 	left: -8px;
        // 	top: 8px;
        // 	content: '';
        // 	border-right: 10px solid #fff;
        // 	border-top: 8px solid transparent;
        // 	border-bottom: 8px solid transparent;
        // }
      }
    }
    .recall {
      display: flex;
      justify-content: center;
      padding:10px 0;
      //width: 100%;
      .recallTips {
        // text-align: center;
        width: fit-content;
        padding: 8px 14px;
        color: black;
        border-radius: 8px;
        background-color: #ebe9e9;
      }
    }
    .c-details{
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 10px;
      .detailsRejectTips {
        background-color: #ebe9e9;
        color: #ff8400;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 8px;
      }
      .detailsAgreeTips {
        background-color: #fff0e0;
        color: #ff8400;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 8px;
      }
    }
  }

  .word-my {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .info {
      width: 90%;
      margin-left: 10px;
      text-align: right;
      .time {
        font-size: 12px;
        color: rgba(51, 51, 51, 0.8);
        margin: 0;
        height: 20px;
        line-height: 20px;
        margin-top: -5px;
        margin-right: 10px;
      }
      .info-content {
        max-width: 100%;
        padding: 10px;
        font-size: 14px;
        float: right;
        margin-right: 10px;
        position: relative;
        margin-top: 4px;
        background: #00bcff;
        color: #ffffff;
        border-radius: 20px 0px 20px 0px;
        text-align: left;
        word-break:break-all;
        word-wrap:break-word;
        white-space:pre-wrap;
      }
      .info-recall {
        max-width: 158px;
        float: right;
        margin-top: 14px;
      }
      //小三角形
      // .info-content::after {
      // 	position: absolute;
      // 	right: -8px;
      // 	top: 8px;
      // 	content: '';
      // 	border-left: 10px solid #a3c3f6;
      // 	border-top: 8px solid transparent;
      // 	border-bottom: 8px solid transparent;
      // }
    }
  }
}
.tt-tishi {
  background-color: #f3f3f3;
  width: 400px;
  padding: 4px 0;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  //border: 1px solid #a0a0a0;
  border-radius: 5px;
  display: flex;

  .text {
    width: 75%;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    white-space: nowrap;
    color: #999999;
    cursor: auto;
  }

  .span {
    width: 25%;
    margin-left: 8px;
    font-size: 10px;
    color: cornflowerblue;
    text-decoration: underline;
    cursor: pointer;
  }
}

.job-select {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  margin: 3px auto 0 auto;
  align-items: center;
  justify-items: center;
  background: #91bbe7;
  border: 1px solid rgba(52, 73, 94, 0.2);
  border-radius: 0.2rem;
  padding: 3px 5px;
  height: 30px;
  max-width: 800px;

  p {
    padding: 3px;
    z-index: 1;
    cursor: pointer;
    width: 5.8rem;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-divider {
    margin: 0 0.5rem;
  }
}

.chat-input {
  height: 140px;
  border-top: 12px solid #f3f3f3;
  .c-caozuo {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    position: relative;
    img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }

    .langage::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 3px;
      height: 1px;
    }
    .langage::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 4px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: #a0a0a0;
    }
    .langage {
      position: absolute;
      top: -150px;
      left: 20px;
      z-index: 999;
      width: 300px !important;
      height: 140px;
      background-color: #ffffff;
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.15) !important;
      overflow: auto;
      .xiala {
        position: absolute;
        bottom: -6px;
        left: 16px;
        width: 16px;
        height: 8px;
      }
      .lang-main:hover {
        cursor: pointer;
      }
      .lang-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        border-top: 1px solid #f4f4f5;
        .lm-text {
        }
        .set {
          color: #00bcff;
        }
      }
    }
    .emoji {
      position: absolute;
      top: -90px;
      left: 10px;
      z-index: 999;
      width: 180px !important;
      height: 80px;
      background-color: #ffffff;
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.15) !important;
      overflow: auto;
      display: flex;
      padding: 5px;
      flex-wrap: wrap;
      > span {
        cursor: pointer;
        padding: 0 2px 2px 2px;
      }
      > .emijiBox {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .jianli {
      position: absolute;
      top: -150px;
      left: 125px;
      z-index: 999;
      width: 300px !important;
      height: 140px;
      background-color: #ffffff;
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.15) !important;
      .lang-main:hover {
        cursor: pointer;
      }
      .lang-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        border-top: 1px solid #f4f4f5;
        .lm-text {
        }
        .set {
          color: #00bcff;
        }
      }
      .xiala1 {
        position: absolute;
        bottom: -6px;
        left: 16px;
        width: 16px;
        height: 8px;
      }
      .jlbox {
        height: 99px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 35px;
          height: 45px;
        }
      }
    }
  }
  .chat-input-box {
    position: relative;
    .send_msg {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    /deep/.el-button {
      position: absolute;
      bottom: 10px;
      right: 30px;
      border-radius: 0px;
      width: 80px;
      height: 32px;
      background: #f3f3f3;
      color: #111111;
      border: none;
      z-index: 999;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .input-textarea {
    }
    /deep/ .el-textarea__inner {
      border-radius: 0px;
      border: none;
      width: 100%;
      color: #111111;
      resize: none;
    }
  }
}
</style>
