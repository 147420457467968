<template>
  <div class="">
    <div class="woshou">
      <div class="box stage-blank" :class="{ active: active == 1 }" @click="changeTab(1)">
        <!-- <div class="bg-pic3">

				</div> -->
        <span>全部消息</span>
      </div>
      <div class="box" :class="{ active: active == 2 }" @click="changeTab(2)">
        <!-- <div class="bg-pic2">

				</div> -->
        <span>未读消息</span>
      </div>
      <div class="box" :class="{ active: active == 3 }" @click="changeTab(3)">
        <!-- <div class="bg-pic3">

				</div> -->
        <span>已握手</span>
      </div>
      <div class="box" :class="{ active: active == 4 }" @click="changeTab(4)">
        <!-- <div class="bg-pic3">

				</div> -->
        <span>已接受offer</span>
      </div>
      <div class="box" :class="{ active: active == 5 }" @click="changeTab(5)">
        <!-- <div class="bg-pic3">

				</div> -->
        <span>沟通过</span>
      </div>
    </div>
    <div class="">
      <chat :navActive="active"></chat>
    </div>
  </div>
</template>

<script>
import chat from "./chat.vue";
export default {
  components: {
    chat,
  },
  data() {
    return {
      active: 1,
    };
  },
  watch: {
    $route(value) {
      if (value.query.active) {
        this.active = value.query.active;
      } else {
        this.active = 1;
      }
    },
  },
  methods: {
    changeTab(i) {
      //   this.active = i;
      this.$router.push({
        path: "/chatmain",
        query: {
          active: i,
        },
      });
    },
  },
  created() {
    if (this.$route.query.active) {
      this.active = this.$route.query.active;
    } else {
      this.active = 1;
    }
    // this.changeTab(1);
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.woshou {
  height: 42px;
  margin-bottom: 20px;
  // background-color: #FFFFFF;
  display: flex;
  align-items: center;
			& > div {
				padding: 0px 10px 0 30px;
				line-height: 40px;
				background: #dddddd;
				display: inline-block;
				position: relative;
        margin-left: 5px;
			}

			& > div:after {
				content: '';
				display: block;
				border-top: 20px solid #dddddd;
				border-bottom: 20px solid #dddddd;
				border-left: 20px solid #fff;
				position: absolute;
				right: -20px;
				top: 0;
			}

			& > div:after {
				content: '';
				display: block;
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
				border-left: 20px solid #dddddd;
				position: absolute;
				right: -20px;
				top: 0;
				z-index: 10;
			}

			& > div:before {
				content: '';
				display: block;
				border-top: 20px solid #dddddd;
				border-bottom: 20px solid #dddddd;
				border-left: 20px solid #fff;
				position: absolute;
				left: 0px;
				top: 0;
			}

			& > div:first-child {
				border-radius: 4px 0 0 4px;
				padding-left: 25px;
			}

			& > div:last-child{
				border-radius: 0px 4px 4px 0px;
				padding-right: 25px;
			}
			& > div:first-child:before{
        display: none;
      }
			// & > div:first-child:after {
			// 	content: '';
			// 	border-top: 20px solid #50abe4;
			// 	border-bottom: 20px solid #50abe4;
			// 	border-left: 20px solid #fff;
			// 	position: absolute;
			// 	right: 0px;
			// 	top: 0;
			// }

			& > div:last-child:after{
				display: none;
			}

			& > div.active {
				background-color: #00bcff;
			}

			& > div.active:after {
				border-left-color: #00bcff;
			}
      & > div.active:before {
				border-top-color: #00bcff;
        border-bottom-color: #00bcff;
			}
  > .active {
    background-color: #dddddd;
    color: #fff;
  }
  .wone:hover {
    cursor: pointer;
  }
  .wone {
    width: 100px;
    height: 42px;

    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 16px;
    span {
      color: #ffffff;
      position: relative;
    }
    .bg-pic {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: url(../../assets/images/common/bg-1.png) no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }
  .wtwo:hover {
    cursor: pointer;
  }
  .wtwo {
    width: 100px;
    height: 42px;
    margin-left: -12px;
    color: #111;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    span {
      color: #111;
      position: relative;
    }
    .bg-pic2 {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: url(../../assets/images/common/bg-4.png) no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }
  .wthree:hover {
    cursor: pointer;
  }
  .wthree {
    width: 120px;
    height: 42px;
    margin-left: -11px;
    color: #111;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    span {
      color: #111;
      position: relative;
    }
    .bg-pic3 {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: url(../../assets/images/common/bg-2.png) no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }
}
</style>
